import {useEffect,useState} from 'react';
import {connect} from 'react-redux';
import analyticsService from '../../../../shared/analytics/analytics-service';
import {Vanpooler} from '../../vanpooler/Vanpooler';


function ThirdScreen(props) {

  const [participants,updateParticipants] = useState([]);

  useEffect(() => {
    analyticsService.getPageName({
      'pagename': "customer onboarding/meet your vanpool",
      'process': "customer onboarding"
    });
  },[]);

  useEffect(() => {
    if(props.selectedVan && props.selectedVan.participants) {
      updateParticipants(props.selectedVan.participants);
    }
  },[props.selectedVan])
  return (
    <div className='third-screen screen-container'>
      <div className='heading'>
        Meet your vanpool
      </div>
      <div className='content-container'>
        <div className='vanpoolers'>
          {participants && participants.map((participant) => <Vanpooler key={participant.participantId} participant={participant} />)}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    selectedVan: state.joinCommute.selectedVan
  }
}

export default connect(mapStateToProps,null)(ThirdScreen)