import * as actionTypes from './redux/actionTypes';
import analyticsService from './shared/analytics/analytics-service';

const initialState = {
  location: {},
  showBackdrop: false,
  apiErrorOccurred: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.URL_CHANGED:
      analyticsService.pageChange(action.data.location);
      return { ...state, ...action.data };
    case actionTypes.SHOW_BACKDROP:
      return { ...state, showBackdrop: true };
    case actionTypes.HIDE_BACKDROP:
      return { ...state, showBackdrop: false };
    case actionTypes.API_ERROR_OCCURRED:
      return { ...state, apiErrorOccurred: true };
    case actionTypes.RESET_API_ERROR:
      return { ...state, apiErrorOccurred: false };
    default:
      return state;
  }
};
