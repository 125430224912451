import Dropdown from '../../../../join-commute/payment-screen/dropdown/Dropdown';
import Tick from '../../../../_styles/images/tick.svg';
import Close from '../../../../_styles/images/close-icon-green.svg';
import AddIcon from '../../../../_styles/images/new-icons/Add.svg';
import GreenCautionIcon from '../../../../_styles/images/new-icons/GreenCaution.svg';
import Callable from '../../../../shared/Callable';

export default function AddLicenseCard(props) {

  function onCheckboxKeydown(e) {
    if (e.which === 13) {
      props.updateIsChecked(!props.isChecked);
    }
  }

  function onRemoveKeyDown(e) {
    if (e.which === 13) {
      props.removeLastLicense();
    }
  }

  return (
    <div className='add-license-container'>
      <h3 className='heading'>Add Previous License(s)</h3>
      <div className='comment'>Please add additional licenses that would add up to 5+ years of driving experience in the U.S.</div>
      <div className='condition'>
        {props.isChecked ?
          <div role='checkbox' className='checkbox selected' aria-describedby='Checked to show you do not have any previous licenses' tabIndex={0} onClick={() => props.updateIsChecked(!props.isChecked)} onKeyDown={onCheckboxKeydown}>
            <img src={Tick} />
          </div>
          :
          <div role='checkbox' className='checkbox' aria-describedby='Check if you do not have any previous licenses' tabIndex={0} onClick={() => props.updateIsChecked(!props.isChecked)} onKeyDown={onCheckboxKeydown}></div>
        }
        <div className='text'>I don’t have any previous license(s)</div>
      </div>
      {!props.isChecked && <div className='license-list'>
        {props.licenses.map((license, index) =>
          <div className='license-info' key={index}>
            {index == 1 && <div className='charges-header'>
              <img className='close' src={Close} onClick={props.removeLastLicense} onKeyDown={onRemoveKeyDown} role='button' tabIndex={0} />
            </div>}
            <fieldset className='field'>
              <label htmlFor='license_number' className='label'>Driver’s License Number*</label>
              <input id='license_number' tabIndex={0} placeholder='Driver’s license number' value={license.licenseNumber} onChange={(e) => props.changeLicense('licenseNumber', e.target.value, index)} />
            </fieldset>
            <fieldset className='field'>
              <Dropdown
                label="State Issued*"
                placeholder='State'
                onChange={(val) => props.changeLicense('licenseState', val, index)}
                options={props.states}
                value={license.licenseState}
              />
            </fieldset>
            {props.licenses.length <= 1 && !props.isChecked && <div className='buttons'>
              <button className='add-license' onClick={props.addLicense}>
                <img src={AddIcon} />
                add another U.S. license</button>
            </div>}
          </div>
        )}
      </div>}
      {
        props.isChecked &&
        <div className='cannot-confirm-dialog'>
          <div className='caution-icon'>
            <img src={GreenCautionIcon} />
          </div>
          <div className='dialog-heading'>We cannot confirm you as a driver for your vanpool</div>
          <div className='second-heading' >You must have five years driving experience.</div>
          <div className='notes'>You can continue as a rider in your vanpool and can reapply to be a driver again once you meet the requirements.</div>
          <div className='second-notes'>If you have any questions or concerns, please contact <Callable className="greentext" phone="800 VAN 4 WORK" /></div>
        </div>
      }
    </div>
  );
}