import { connect } from 'react-redux';
import Savings from './savings';
import { useState, useEffect } from 'react';
import * as actionTypes from '../../redux/actionTypes';
import { PERMISSION_VANPOOL_STATUS_RENTAL } from '../../shared/permissions/permissions';
import { isAllowed } from '../../shared/permissions/RestrictTo';

function EnvironmentDetails(props) {
  const { vanpool, rideDays } = props;
  const [isIndividual, showIndividuals] = useState(true);
  const [isVanpools, showVanpools] = useState(false);
  const [impactData, setImpactData ] = useState({})

  useEffect(() => {
    if(!props.impactDataLoaded){
      props.loadImpactData();
    }
  }, [])
  useEffect(() => {
    if(props.vanpoolImpactData && props.individualImpactData) {
      setImpactData(props.individualImpactData)
    }
  }, [props.vanpoolImpactData,props.individualImpactData])

  const showData = (data) => {
      if(data == "showIndividuals") {
        showIndividuals(true);
        showVanpools(false);
        setImpactData(props.individualImpactData)
      }else {
        showVanpools(true);
        showIndividuals(false);
        setImpactData(props.vanpoolImpactData)
      }
  }

  function onKeydownIndividuals(e){
    if(e.which === 13){
      showData('showIndividuals');
    }
  }

  function onKeydownVanpools(e){
    if(e.which === 13){
      showData('showVanpools');
    }
  }


  return (
    <div className="environment-information">
      <div className='heading'>
          Savings <span>per month</span>
      </div>
      
            <div className="tabs">
              <div
                className={`tab ${isIndividual ? 'active' : ''}`}
                onClick={() => showData('showIndividuals')}
                onKeyDown={onKeydownIndividuals}
                role='button'
                tabIndex='0'
                aria-label='Individual savings'
              >
               Individuals
              </div>
              <div
                className={`tab ${isVanpools ? 'active' : ''}`}
                onClick={() => showData('showVanpools')}
                onKeyDown={onKeydownVanpools}
                role='button'
                tabIndex='0'
                aria-label='Vanpool savings'
              >
                Vanpools
              </div>
              {/* <hr /> */}
            </div>
             <Savings  {...impactData} />
    </div>
  );
}
function mapStateToProps(state) {
  return {
   vanpoolImpactData : state.impactData.vanpool.current,
   individualImpactData : state.impactData.participant.current,
   isImpactDataLoaded: state.impactData.impactDataLoaded
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadImpactData() {
      if(isAllowed([PERMISSION_VANPOOL_STATUS_RENTAL])){
        dispatch({ type: actionTypes.VANPOOLER_IMPACT_DATA_LOADING})
      }
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnvironmentDetails);
