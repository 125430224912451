import { useEffect } from 'react';
import Success from '../../../_styles/images/Success.svg';
import analyticsService from '../../../shared/analytics/analytics-service';

export default function SuccessPopup(props) {

  useEffect(() => {
    analyticsService.analyticsProcessEvent(
      {
        "event": "personal_information_updated_modal_view",
        "context": {
          "event_action": "personal information updated modal"  
          }
      });
  }, [])
  return (
    <div className='card-operations-container' ref={props.reference}>
      <div className='delete-confirmation-container'>
        <div className='caution-icon'>
          <img src={Success}></img>
        </div>
        <div className='heading' tabIndex={0}>{props.heading}</div>
        <div className='foot-notes' tabIndex={0}>{props.text}</div>
        <div className='buttons'>
          <button className='confirmation-delete-button' disabled={false} tabIndex={0} onClick={props.confirm}>{props.confirmButtonText}</button>
        </div>
      </div>
    </div>
  );
}
