import SUV from '../../../_styles/images/SUV.svg';
import CoSaved from '../../../_styles/images/CoSavings.svg';
import CostSavings from '../../../_styles/images/CostSavings.svg';
import FuelSaved from '../../../_styles/images/FuelSavings.svg';
import Time from '../../../_styles/images/placeholder-time.svg';
import People from '../../../_styles/images/people.svg';
const bottomSection = [
    {
        img: Time,
        text: 'Gain valuable time for catching up on work or just relaxing.'
    },
    {
        img: CostSavings,
        text: 'Split costs and save money.'
    },
    {
        img: CoSaved,
        text: 'Reduce your carbon footprint.'
    },
    {
        img: FuelSaved,
        text: 'Save fuel and contribute to a sustainable future.'
    },
    {
        img: People,
        text: 'Make new friends and enjoy a stress-free commute!'
    }
]

export default function JoinVanpoolSection(props) {

    function goToFindAVan(){
        props.history.push('/JoinCommute');
    }
    return (
        <div className='join-vanpool'>
            <span className="header">Join a Vanpool</span>
            <span className="sub-header">Say goodbye to the hassle of driving alone to work!</span>
            <div className='vanpool'>
                <img src={SUV} aria-hidden='true' />
            </div>
            <div className='bottom-section'>
                <ul>
                    {bottomSection.map((each, index) =>
                        <li key={index}>
                            <img src={each.img} className='icon' />
                            <span>{each.text}</span>
                        </li>
                    )}
                </ul>
            </div>
            <div className='button-container'>
                <button className='continue' onClick={goToFindAVan}>find A vanpool</button>
            </div>
        </div>
    );
}