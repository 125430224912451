import React from 'react';

export default function SliderLevel(props) {
  const { notification, checked, onChange, disabled, selected } = props;
  return (
    <div className="slider-container">
      {notification &&
       <div className='left'>
          <div className="text">{notification.label}</div>
          <div className="toggle">
            <div className="selected">{selected ? "Yes" : "No"}</div>
            <label className="switch">
              <input
                type="checkbox"
                className="tog-btn"
                checked={!!checked}
                onChange={onChange}
                disabled={disabled}
              />
              <div className="slider round">
                <span className="on" />
                <span className="off" />
              </div>
            </label>
        </div>
      </div>}

      <div className="label">{notification.text}</div>
    </div>
  );
}
