import React, { useEffect } from 'react';
import DollarInput from '../../shared/inputs/DollarInput';
import { Dollar } from '../../shared/icons';
import DateTimePicker from 'react-datetime-picker';
import dateUtility from '../../shared/dateUtility';
import analyticsService from '../../shared/analytics/analytics-service';

export default function AddOtherExpense({
    props,
    state,
    vendor,
    activityDate,
    subtract,
    add,
    dollars,
    onChange,
    onOtherError,
    month
}) {

    const expenses = state;
    let maxDate, minDate;
    if(activityDate == "") {
        activityDate = new Date();
    }
    if(month == "previous") {
        var date = new Date();
        minDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        maxDate = new Date(date.getFullYear(), date.getMonth() - 1 + 1, 0);
        var current = dateUtility.checkPreviousDate(activityDate,maxDate);
        if(current) {
            activityDate = activityDate;
        }else {
            activityDate = maxDate
        }
    }else{
        var date = new Date();
        minDate = new Date(date.getFullYear(), date.getMonth(), 1);
        maxDate = date //new Date(date.getFullYear(), date.getMonth() + 1, 0); 
    }

    function validFields() {
        const hasfuelCost = (expenses.dollars !== '0.00' && expenses.dollars !== '0' && expenses.dollars !== "" );
        const hasVendorName = (expenses.vendor !== "" && expenses.vendor !== undefined);
        const isdatevalid = validatedate(expenses.activityDate);
        if(hasfuelCost && hasVendorName && isdatevalid) {
            return false
        }else {
            return true
        }
    }

    function handleChange(date) {
        onChange({ activityDate: date, vendor, dollars })
    };

    function validatedate(value) {
       var date = dateUtility.formatexpenses(value);
     //  var  validregex = /^\d\d\/\d\d\/\d{4}$/;
       var validregex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/
        return validregex.test(date);
    }

     function fieldValidation(field) {
        if(field == "vendor"){
        return (expenses.vendor !== "" && expenses.vendor !== undefined)
        } else if (field == "dollars") {
            return (expenses.dollars !== '0.00' && expenses.dollars !== '0' && expenses.dollars !== "" )
        } else if(field == "activity") {
            return validatedate(expenses.activityDate)  
        }
    }

    useEffect(() => {
        analyticsService.analyticsProcessEvent({
            "event": "modal_click_generic",
            "context": {
              "event_action": 'other expenses',
              "event_detail": {
                  "modal": "trip recording/add expense modal"
                  }
            }
          })
    }, [])

    return (
        <div className="addexpenses">
            <div className="expensesInput activity">
                <label>Activity Date</label>
              <DateTimePicker
              onChange={ date => handleChange(date)}
              format={'MM/dd/yyyy'}
              disableClock={true}
              value={activityDate}
              clearIcon={null}
              required={true}
              maxDate={maxDate}
              minDate={minDate}
            />
            </div>
            <div className="expensesInput">
                <label>Vendor Name</label>
                <input
                    type="text"
                    className={`vendor ${ fieldValidation('vendor') ? '' : 'invalid'}`}
                    placeholder="Vendor"
                    value={vendor}
                    onChange={e => onChange({ vendor: e.target.value, dollars, activityDate })
                    }
                />
            </div>
            <div className="expensesInput">
                <label>Other Expenses</label>
                <Dollar />
                <DollarInput
                    type="text"
                    className={`dollars ${ fieldValidation('dollars') ? '' : 'invalid'}`}
                    placeholder="0.00"
                    value={dollars}
                    onChange={dollars => onChange({ vendor, dollars, activityDate })}
                />
            </div>

            <button
                className="primary add button"
                onClick={add}
                disabled={validFields()}
            >
                Add to Expenses
        </button>
        </div>
    );
}