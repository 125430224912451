import React, { Component } from 'react';
import { Pencil, Home, Briefcase, Vanpool } from '../../shared/icons';
import { RestrictTo } from '../../shared/permissions/RestrictTo';
import {
  TRADITIONAL_COORDINATOR_PERMISSION
} from '../../shared/permissions/permissions';
import LocationGreen from '../../_styles/images/LocationGreen.png';
import startLocation from '../../_styles/images/Subtract.png';
import Line from '../../_styles/images/Line.png';
import dateUtility from '../../shared/dateUtility';

export default class NewParticipantViewer extends Component {
  render() {
    const {
      rideDays,
      toHomeEnd,
      toHomeStart,
      toWorkEnd,
      toWorkStart,
      edit
    } = this.props;

    return (
      <div className="participant-route">
        <div className="route-days">
          <div className="flex-grow">
            <div className="schedule-label">Commute Details</div>
            <div className='commute-days'>
            <div className='commute-label'>Commute days</div>
            <div className='days'>
              {rideDays.map(day => (
                <div key={day} className="day-btn">
                  {day.substr(0, 3)}
                </div>
              ))}
            </div>
            </div>

          </div>
          <RestrictTo
            roles={[
                TRADITIONAL_COORDINATOR_PERMISSION
            ]}
            required={false}
          >
            {/* <Pencil onClick={edit} className="edit-icon" /> */}
            <div className="edit-icon" onClick={edit}>EDIT</div>
          </RestrictTo>
        </div>
        <div className="route-set">
          <div className="to-work route-type">
            <div className='route-label'>
                To Work
            </div>
            <ParticipantRoute start={toWorkStart} end={toWorkEnd} />
          </div>
          <div className="to-home route-type">
            <div className='route-label'>
             To Home
            </div>
            <ParticipantRoute start={toHomeStart} end={toHomeEnd} />
          </div>
        </div>
      </div>
    );
  }
}

function ParticipantRoute({ start, end }) {
  return start && end ? (
    <div className="van-route-details">
    <div className="route-icon" aria-hidden='true'>
      <img className="group" src={startLocation} />
      <img className="line" src={Line} />
      <img className="LocationGreen" src={LocationGreen} />
    </div>
    <div className="route-address" role='group'>
      <div className="origin-address" role='group'>
        <div className="start-address" aria-label={`start address, ${start.address}`}>{start.address}</div>
        <div className="start-timings">{`Depart: ${dateUtility.parseTime(start.stopTime)}`}</div>
      </div>
      <div className="destination-address" role='group'>
        <div className="end-address" aria-label={`end address, ${end.address}`}>{end.address}</div>
        <div className="end-timings">{`Arrive: ${dateUtility.parseTime(end.stopTime)}`}</div>
      </div>
    </div>
  </div>
  ) : null;
}
