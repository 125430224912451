import {useEffect,useState} from 'react';
import {connect} from 'react-redux';
import {SET_USER_DRIVING_SCHEDULE, UPDATE_USER_COMMUTE_DATA} from '../../../redux/actionTypes';
import UpgradeVehicle from '../../../_styles/images/upgradeVehicle.svg'
import {getActiveDaysFromRoosterDays, getAvailableDaysOfVan} from '../../helpers/vanHelpers';
import CommuteDays from '../CommuteDays';
import analyticsService from '../../../shared/analytics/analytics-service';
import { getUpdatedRoosterDays } from '../../helpers/commonHelper';

const MAXDAYS = 7;

function Upgrade(props) {

  const [maxDays,updateMaxDays] = useState(0);
  const [vanAvailableDays, updateVanAvailableDays] = useState([]);
  const [selectedDays, updateSelectedDays] = useState([]);
  const [isError,updateIsError] = useState(false);
  const [newlySelectedDays,updateNewlySelectedDays] = useState([]);
  const [isDisabled,updateIsDisabled] = useState(true);
  const [isDriver, updateIsDriver] = useState(false);

  useEffect(() => {
    const newPackage = props.joinCommuteData.plan.noOfDays === 5 ? 'unlimited!' : props.joinCommuteData.plan.noOfDays + "-day select";

    analyticsService.analyticsProcessEvent({
      "event": "view_upgraded",
      "context": {
        "event_action":"upgraded to:" + newPackage
      }
    });

    analyticsService.pageInfo("choose subscription");
  },[])
  

  useEffect(() => {
    if(props.joinCommuteData.plan.noOfDays >= 5) {
      updateMaxDays(MAXDAYS);
    } else {
      updateMaxDays(props.joinCommuteData.plan.noOfDays);
    }
    updateIsDriver(props.joinCommuteData.plan.isDriver);
    const availableDays = getAvailableDaysOfVan(props.joinCommuteData.selectedVan);
    updateVanAvailableDays(availableDays);
    const days = getActiveDaysFromRoosterDays(props.joinCommuteData.oldPlan.rosterDays);
    updateSelectedDays(days);
    const newDays = getActiveDaysFromRoosterDays(props.joinCommuteData.userCommuteData.rosterDays);
    updateSelectedDays(days);
    updateNewlySelectedDays(newDays);
  },[props.joinCommuteData.plan, props.joinCommuteData.userCommuteData, props.joinCommuteData.selectedVan, props.joinCommuteData.oldPlan])
  
  function daysChanged(day) {
    if(newlySelectedDays.indexOf(day) === -1) {
      updateNewlySelectedDays([...newlySelectedDays,day]);
    } else {
      const newArr = newlySelectedDays.filter((value) => value !== day);
      updateNewlySelectedDays(newArr);
    }
  }
  
  useEffect(() => {
    if(maxDays >= newlySelectedDays.length) {
      if(isError) {
        updateIsError(false)
      }
    } else {
      if(!isError) {
        updateIsError(true);
      }
    }
  }, [newlySelectedDays])
  
  useEffect(() => {
    if(maxDays === MAXDAYS){
      //for unlimited
      if(!isError){
        updateIsDisabled(false);
      } else {
        updateIsDisabled(true);
      }
    } else {
      if(!isError && (newlySelectedDays.length <= maxDays)){
        updateIsDisabled(false);
      } else {
        updateIsDisabled(true);
      }
    }
  }, [isError, newlySelectedDays])
  
  function onContinue() {
    const rosterDays = getUpdatedRoosterDays(newlySelectedDays, true);
    props.updateUserCommuteData({
      rosterDays
    })
    if(isDriver){
      props.updateDriverData({
        isDesiredDriverRole: true,
        role: 1,
        isFrequentDriver: false,
      },)
    }

    const activeRosterDays = getActiveDaysFromRoosterDays(rosterDays);
    const newPackage = props.joinCommuteData.plan.noOfDays === 5 ? 'unlimited!' : props.joinCommuteData.plan.noOfDays + "-day select";

    analyticsService.analyticsProcessEvent({
      "event": "upgraded_subscription_continue",
      "context": {
        "event_action": "continue",
        "event_detail": {
            "days_selected": activeRosterDays.map(day => day.slice(0, 2).toLowerCase()).join('.')+':'+ newPackage
            }
        }
    });

    props.history.push('/JoinCommute/choose-plan/summary');
  }

  return (
    <div className='upgrade-page'>
      <div className='upgrade-container'>
        <div className='content-container'>
          <div className='left-container'>
            <div className='heading-container'>
              {maxDays <= 4
                ?
                <div className='heading'>You’ve upgraded to {maxDays}-Day Select!</div>
                :
                <div className='heading'>You’ve upgraded to Unlimited Select!</div>
              }
            </div>
            <div className='comment'>The days your vanpool runs are below. We’ve kept the days you already selected</div>
            {maxDays <= 4 
              ?
              <div className='sub-heading'>Add 1 more day to confirm your new weekly schedule</div>
              :
              <div className='sub-heading'>Add up to {vanAvailableDays.length-selectedDays.length} more days to confirm your new weekly schedule.</div>
              }
            <div className="commuteDays">
              <CommuteDays
                days={vanAvailableDays}
                preselected={selectedDays}
                isError={isError}
                newlySelectedDays={newlySelectedDays}
                daysChanged={daysChanged}
                maxDays = {maxDays}
              /> 
            </div>
          </div>
          <div className='right-container'>
            <img className='commute-image' src={UpgradeVehicle} alt="commute-image" />
          </div>
        </div>
        <div>
          <button disabled={isDisabled} className='continue-button' onClick={onContinue}>CONTINUE</button>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    joinCommuteData: state.joinCommute
  }
};

function mapDispatchToProps(dispatch) {
  return {
    updateUserCommuteData(data) {
      dispatch({type: UPDATE_USER_COMMUTE_DATA, data: data, updateOldPlan: false})
    },
    updateDriverData(data) {
      dispatch({type: SET_USER_DRIVING_SCHEDULE, data: data})
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Upgrade);