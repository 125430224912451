import { MONTHS } from '../../../inputs/Calendar/Calendar';

export default function DatePickerCustom(props) {
  const {days, label, placeholder, selectedDay, toggleCalendar, onCalendarKeydown, footerText} = props;
  return (
    <div className="date-picker-custom" role='group'>
      {days === 5 ?
        <div className='plan-heading'>Unlimited Select Plan</div>
        :
        <div className='plan-heading'>{days}-Day Select Plan</div>
      }
      <div className='picker-container'>
        <label>{label}</label>
        <button className='picker' onClick={toggleCalendar} role='button' onKeyUp={onCalendarKeydown}>
          {selectedDay ?
            <div className='date-display'>
              <div className='day'>{selectedDay.getDate()}</div>
              <div className='month'>{MONTHS[selectedDay.getMonth()]}</div>
              <div className='year'>{selectedDay.getFullYear()}</div>
            </div>
            :
            <div className='placeholder'>
              <span className='text'>{placeholder}</span>
            </div>
          }
          <i className="time-arrow down"></i>
        </button>
        <div className='foot-text'>{footerText}</div>
      </div>
    </div>
  );
}