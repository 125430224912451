import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import analyticsService from '../../../../shared/analytics/analytics-service';
import { UPLOAD_PROFILE_PICTURE, RESET_UPLOADING_ERROR } from '../../../../redux/actionTypes';
import PlaceHolder from '../../../../_styles/images/profile-placeholder.svg';
import CropPicture from '../../../common/crop-picture/CropPicture';
import CommonToast from '../../../../shared/components/common-toast-message/CommonToast';

function FourthScreen(props) {
  const hiddenFileInput = useRef(null);

  const [imageSrc, setImageSrc] = useState();
  const [croppedImage, setCroppedImage] = useState(props.uploadedImage);
  const [isOpen, setIsOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const handleClick = () => {
    hiddenFileInput.current.click();
    props.uploadedImage ? analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "replace current picture"
      }
    }) :
      analyticsService.analyticsProcessEvent({
        "event": "click_generic",
        "context": {
          "event_action": "upload from device"
        }
      });
  };

  useEffect(() => {
    analyticsService.getPageName({
      'pagename': "customer onboarding/add a profile picture",
      'process': "customer onboarding"
    });
  }, []);

  useEffect(() => {
    setCroppedImage(props.uploadedImage);
  }, [props.uploadedImage])

  function finalizeImage(image) {
    if (image && image != '') {
      props.uploadPicture(image);
    }
  }

  const handleFileLoad = async event => {
    const fileUploaded = event.target.files[0];
    const fileURL = URL.createObjectURL(fileUploaded);
    //setting image to be cropped and opening the popup
    const all = document.querySelector(".content-container");
    all.setAttribute("aria-hidden", "true");
    setImageSrc(fileURL);
    setIsOpen(true);
  }

  useEffect(() => {
    if (props.isErrorUploading) {
      setIsErrorModalOpen(true);
      analyticsService.analyticsProcessEvent({
        "event": "user_error",
        "context": {
          "event_action": "invalid image. Please upload a photo where your face is clearly visible"
        }
      })
    }
  }, [props.isErrorUploading]);

  function closeToast() {
    setIsErrorModalOpen(false);
    props.resetError();
  }

  return (<div className='fourth-screen screen-container'>
    <div className='heading'>
      Add a Profile Picture
    </div>
    <div className='content-container' role='group'>
      <div className='content'>
        <div className='section profile-pic'>
          {props.imageLoading ?
            <span aria-live='assertive' role='alert'>
              <div className="image-loader" aria-busy='true'><span className='screenreader-only'>uploading image</span></div>
            </span>
            :
            croppedImage ?
              <img className='profile-image' src={croppedImage} alt='profile image' />
              :
              <img className='placeholder' src={PlaceHolder} alt="" aria-hidden='true' />
          }
          <input
            type="file"
            ref={hiddenFileInput}
            style={{ display: 'none' }}
            onClick={(e) => { e.target.value = '' }}
            onInput={handleFileLoad}
            accept="image/jpeg"
          />
        </div>
        <div className='section description'>
          <div className='select-heading'>Select your picture</div>
          <div className='select-description'>
            Your profile picture is required so the vanpool members can easily recognize you. Please upload an image where your face is clearly visible.
          </div>
          <div className='button'>
            {
              props.uploadedImage ?
                <button className="replace" onClick={handleClick} autoFocus='true'>replace current picture</button> :
                <button onClick={handleClick} autoFocus={true}>upload from device</button>
            }
            {
              props.uploadErrorOccurred && !(props.imageLoading) &&
              <div className="do-later-link green-link-underline" onClick={props.goToDashboard}>
                do this later
              </div>
            }
          </div>
          <CommonToast message='Invalid image. Please upload a photo where your face is clearly visible' type='error' isOpen={isErrorModalOpen} closeToast={closeToast} timeOut={6000} />
        </div>
      </div>
    </div>
    {isOpen && <div className="crop-popup show" >
      <CropPicture src={imageSrc} setCompletedCrop={finalizeImage} close={() => setIsOpen(false)} />
    </div>}
  </div>);
}

function mapStateToProps(state) {
  return {
    uploadedImage: state.joinCommute.profile.image,
    imageLoading: state.joinCommute.profile.imageLoading,
    isErrorUploading: state.joinCommute.profile.imageUploadError,
    uploadErrorOccurred: state.joinCommute.profile.uploadErrorOccurred,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uploadPicture(data) {
      dispatch({ type: UPLOAD_PROFILE_PICTURE, data })
    },
    resetError() {
      dispatch({ type: RESET_UPLOADING_ERROR })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FourthScreen);