import milesaved from '../../_styles/images/milesaved.png';
import coSaved from '../../_styles/images/coSaved.png';
import costSavings from '../../_styles/images/costSavings.png';
import fuelSaved from '../../_styles/images/fuelSaved.png';
import currencyFormatter from '../../shared/currencyFormatter';
import { numberWithCommas } from '../../join-commute/helpers/commonHelper';

export default function Savings(props) {
  const { milesSaved, fuelGallonsSaved, co2Savings, fuelSavings } = props;
  const formatedCostSavings = currencyFormatter.formatUSD(fuelSavings);

  return (
    <div className="savings">
      <ul>
      <li>
          <div className="start">
            <div className="icon">
              <img src={fuelSaved}  alt='fuel icon'/>
            </div>
            <div className="description">{'Fuel Saved'}</div>
          </div>
          <div className="end">
            {numberWithCommas(fuelGallonsSaved)} <span className="sub">gal</span>
          </div>
        </li>
        <li>
          <div className="start">
            <div className="icon">
              <img src={milesaved} alt='car icon'/>
            </div>
            <div className="description">{'Miles Saved'}</div>
          </div>
          <div className="end">
            {numberWithCommas(milesSaved)} <span className="sub">mi</span>
          </div>
        </li>
        <li>
          <div className="start">
            <div className="icon">
              <img src={coSaved} alt='Carbon dioxide' />
            </div>
            <div className="description">{'CO2 Saved'}</div>
          </div>
          <div className="end">
            {numberWithCommas(co2Savings)} <span className="sub">lbs</span>
          </div>
        </li>
        <li>
          <div className="start">
            <div className="icon">
              <img src={costSavings}  alt='savings icon'/>
            </div>
            <div className="description">{'Cost Savings'}</div>
          </div>
          <div className="end">{numberWithCommas(formatedCostSavings)}</div>
        </li>
      </ul>
    </div>
  );
}
