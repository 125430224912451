import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import MatchedVanpools from './MatchedVanpools';
import TellUsAboutCommute from '../tell-us-about-commute/TellUsAboutCommute';
import { initialJoinCommuteState } from '../JoinCommuteReducer';
import NoVansComponent from '../no-vans/NoVansComponent';
import Loading from '../../shared/loading/Loading';
import analyticsService from '../../shared/analytics/analytics-service';
import { GET_MATCHING_VANPOOLS, LOADER_ACTIVE, RESET_EDIT_COMMUTE_INFO, SET_SELECTED_VAN, SET_USER_COMMUTE_DATA, VAN_LIST_UPDATED } from '../../redux/actionTypes';
import { getVanAvailableDays } from '../helpers/commonHelper';


function ViewMatchingVans(props) {

  const [populateEditCommuteData, setPopulateEditCommuteData] = useState(true);
  const [isCoordinatorInvite, setIsCoordinatorInvite] = useState(false);
  const [enabledDays, updateEnabledDays] = useState([]);

  function updateComponent(data) {
    props.updateLoading(true);
    if(props.joinCommuteState.isCoordinatorInvite){
      data = {
        ...data,
        inviteId: props.joinCommuteState.inviteId
      }
    }
    props.setUserCommuteData(data);
    props.getMatchingVanpools(props.history);
    var activeDays = Object.keys(data.rosterDays).filter(el => data.rosterDays[el] == true);
    var daysString = activeDays.map(d => d.substring(0, 2)).join(".").toLowerCase()
    analyticsService.analyticsProcessEvent({
      "event": "edit_commute_click_update",
      "context": {
        "event_action": "update",
        "event_detail": {
          "days_selected": daysString,
          "arrival_flexibility": data.flexArrivalMinutes,
          "departure_flexibility": data.flexDepartureMinutes,
        }
      }
    });
  }

  useEffect(() => {
    if (JSON.stringify(props.joinCommuteState) === JSON.stringify(initialJoinCommuteState)) {
      props.history.push("/JoinCommute");
    }
    if (!props.joinCommuteState.inviteId) {
      if (!props.joinCommuteState.vanListUpdated) {
        props.updateLoading(true);
        props.getMatchingVanpools(props.history);
      }
      if (props.joinCommuteState.userCommuteData.isUpdated) {
        props.updateLoading(true);
        props.getMatchingVanpools(props.history);
        props.vanListUpdated();
      }
    }
  }, [])

  useEffect(() => {
    if (props.joinCommuteState && props.joinCommuteState.inviteId) {
      if (props.joinCommuteState.isInviteError === true) {
        setPopulateEditCommuteData(false);
      } else {
        setPopulateEditCommuteData(true);
        if (props.joinCommuteState.isCoordinatorInvite) {
          setIsCoordinatorInvite(true);
          const preferredVanpool = props.joinCommuteState.matchingVans.filter(van => van.isPreferredVanpool);
          if (preferredVanpool && preferredVanpool.length > 0) {
            const availableDays = getVanAvailableDays(preferredVanpool[0].operationalDays);
            updateEnabledDays(availableDays);
          }
        }
      }

    }
  }, [props.joinCommuteState])

  useEffect(() => {
    analyticsService.pageInfo("matching vans");
  });

  function goToJoinCommute() {
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "find a new route"
      }
    });
    props.history.push("/JoinCommute");
  }

  function goToStayInTouch() {
    analyticsService.pageInfo("stay in touch");
    analyticsService.analyticsProcessEvent({
      "event": "stay_in_touch_click_continue",
      "context": {
        "event_action": "stay in touch"
      }
    });
    props.history.push("/JoinCommute/stay-in-touch");
  }

  return (
    <div className='vans-container'>
      <span aria-live='polite' role='alert'>
        <Loading isLoading={props.loading} />
      </span>
      {props.matchingVans.length > 0 || props.joinCommuteState.inviteId ?
        <div className='view-matching-vans-container'>
          <div className="update-commute" role='group'>
            <div className='heading'>Edit my commute</div>
            <TellUsAboutCommute
              continueClicked={updateComponent}
              buttonText="UPDATE"
              flow='editCommute'
              prePopulateData={populateEditCommuteData}
              disableEditCommute={isCoordinatorInvite}
              enabledDays={enabledDays}
            />
          </div>
          <MatchedVanpools
            props={props}
            matchingVans={props.matchingVans}
            goToStayInTouch={goToStayInTouch}
            userInfo={props.joinCommuteState}
            setSelectedVan={props.setSelectedVan}
            resetEditCommuteSettings={props.resetEditCommuteSettings}
            isCoordinatorInvite={isCoordinatorInvite} />
        </div> : <NoVansComponent goToJoinCommute={goToJoinCommute} goToStayInTouch={goToStayInTouch} />
      }
    </div>
  );
}

function mapStateToProps(state) {
  return {
    joinCommuteState: state.joinCommute,
    matchingVans: state.joinCommute.matchingVans,
    loading: state.joinCommute.loading
  }
}
function mapDispatchToProps(dispatch) {
  return {
    setUserCommuteData(data) {
      dispatch({ type: SET_USER_COMMUTE_DATA, data });
    },
    getMatchingVanpools(history) {
      dispatch({ type: GET_MATCHING_VANPOOLS, history })
    },
    updateLoading(data) {
      dispatch({ type: LOADER_ACTIVE, data });
    },
    vanListUpdated() {
      dispatch({ type: VAN_LIST_UPDATED });
    },
    setSelectedVan(van) {
      dispatch({ type: SET_SELECTED_VAN, data: van })
    },
    resetEditCommuteSettings() {
      dispatch({ type: RESET_EDIT_COMMUTE_INFO });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewMatchingVans)