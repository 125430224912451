import TickIcon from '../../../_styles/images/tick-badge.svg';
import { numberWithCommas } from '../../helpers/commonHelper';
export default function PriceComponent(props) {

  let priceClass = props.small ? 'price-component-small' : 'price-component-large';
  priceClass += props.selected ? ' selected' : '';

  function optionKeydown(e, opt) {
    if(e.which == 13){
      props.onSelect();
    }
  }

  return (
    <div className='price-comp' onClick={props.onSelect} role='button' tabIndex={0} onKeyDown={(e) => optionKeydown(e)}>
      {
        props.selected && <div className='tick-icon-container'>
          <img className='tick-icon' src={TickIcon} alt="selected" />
        </div>
      }
      <div className={priceClass}>
        {props.small && <div className='sub-heading'>{props.heading}</div>}
        <div className='price-group' aria-label={`${props.charge.price} dollars and ${props.charge.decimal} cents`}>
          <div className="doller">{'$'}</div>
          <div className="price">{numberWithCommas(props.charge.price)}</div>
          <div className="decimal">.{props.charge.decimal}</div>
        </div>
        <div className='frequency'>total per month</div>
      </div>
      </div>
  );
}