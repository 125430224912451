import Close from '../../../_styles/images/close-icon-green.svg';
import Highlight from '../../../_styles/images/Highlight.png';
import { useEffect, useRef } from 'react';
import { cancelReasons } from '../cancelReasons';
import Callable from '../../../shared/Callable';
import dateUtility from '../../../shared/dateUtility';
import analyticsService from '../../../shared/analytics/analytics-service';
import LastDaysDetails from './LastDaysDetails';
import config from '../../../appConfig';
import { getDateObjFromString } from '../../../payment-history/helper';

export default function CancelConfirmationPopup(props) {
  let selectedDay = '';
  let cancellationReasonID = null;
  let role = 'riding';
  if(props.details){
    selectedDay = props.details.selectedDay;
    role = props.details.isDriver ? 'driving' : 'riding';
    if(props.details.subscription)
    cancellationReasonID = props.details.subscription.cancellationReasonID;
  }
  const subscriptionEndDate = props.subscriptionEndDate;
  const selectedReason = cancellationReasonID && cancelReasons.filter(
    c => c.id == cancellationReasonID
  );
  const cancellationDate = selectedDay ? dateUtility.formatSimpleDate(selectedDay) : '';
  const endDateObj = subscriptionEndDate && getDateObjFromString(subscriptionEndDate);
  const endDate = endDateObj && `${endDateObj.monthSubstr} ${endDateObj.day}, ${endDateObj.year}`;
  const closeButtonRef = useRef(null);

  function goToProfile() {
    analyticsService.analyticsProcessEvent({
      "event": "modal_click_generic",
      "context": {
        "event_action": 'done',
        "event_detail": {
          "modal": "your subscription will be canceled modal"
        }
      }
    });
    props.close();
    props.history.push('/myprofile');
  }

  useEffect(() => {
    closeButtonRef.current.focus();
    analyticsService.getPageName({
      'pagename': "profile/manage subscription/cancel subscription confirmation modal"
    });
    analyticsService.analyticsProcessEvent({
      "event": "modal_view_generic",
      "context": {
        "event_action": "your subscription will be canceled modal"
      }
    });
  }, []);

  return (
    <div
      className="charges-popup show"
      tabIndex={0}
      id="modal"
      role="dialog"
      aria-labelledby="cancelConfirmationTitle"
      aria-describedby="cancelConfirmationDescription"
      aria-live="assertive"
    >
      <div className="card-operations-container cancel-confirmation-popup">
        <div className="ccp-header">
        <button
            ref={closeButtonRef}
            className="close"
            onClick={props.close}
            aria-label="Close"
          >
            <img src={Close} alt="" aria-hidden="true" />
          </button>
        </div>
        <div className="cancel-confirmation-popup-content">
          <div className="success-icon">
            <img className="successIcon" src={Highlight} aria-hidden={true}/>
          </div>
          <h2 id="cancelConfirmationTitle" className="message">
            {(props.details && props.details.isCoordinator) ? 'Cancellation pending' : 'Cancellation confirmed'}
          </h2>
          {(props.details && props.details.isCoordinator) || (selectedReason && selectedReason[0] && selectedReason[0].suggestion) ? (
            <div className="messageContainer">
              {(props.details && props.details.isCoordinator)
                ? 'Thank you for your service in the vanpool!'
                : selectedReason[0].suggestion
                  ? selectedReason[0].suggestion.replace('*', role)
                  : null}
            </div>
          ) : null}
          {(props.details && props.details.isCoordinator) ?
            (<div className="confirmationContainer">
              We’ve received your request. A representative will contact you shortly to get your subscription canceled.
            </div>)
            :
            (<LastDaysDetails subDate={cancellationDate} endDate={endDate} />)
          }
          <hr className='line' aria-hidden="true"/>
          {
            !(props.details && props.details.isCoordinator) &&
            <div className='popup-text'>
              <p role='text'>We will send you the final invoice reflecting changes in your subscription.</p>
              <p role='text'>If you need to change your last day of the ride or subscription please call <br/><Callable phone="800 VAN 4 WORK" /></p>
              <p role='text'>An explanation of the invoice process can be found in <a className='terms' href={config.termsOfUseLink} target="_blank" rel="noopener noreferrer">Terms and Conditions</a>.</p>
            </div>
          }
        </div>
        <div className="button-container">
          <button className="noThanks" onClick={goToProfile}>
            Done
          </button>
        </div>
      </div>
    </div>
  );
}
