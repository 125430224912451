import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import dateUtility from '../../shared/dateUtility';
import {Document,Page,pdfjs} from 'react-pdf/dist/esm/entry.webpack';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import Http from '../../shared/http-service';
import { saveAs } from 'file-saver';
import VanpoolModal from '../../join-commute/helpers/vanpoolModal';
import config from '../../appConfig';
import { Close } from '../../shared/icons';
import Download from '../../_styles/images/download-white.png';
import DownloadBlack from '../../_styles/images/downloadBlack.svg';
import Modal from '../../shared/modal/Modal';
import ImageNotAvailable from '../../_styles/images/ImageNotAvailable.svg';
import analyticsService from '../../shared/analytics/analytics-service';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
import { RestrictFrom } from '../../shared/permissions/RestrictTo';
import { PERMISSION_VANPOOL_STATUS_PRE_DELIVERY } from '../../shared/permissions/permissions';

function VanpoolInformation(props) {
  const [pdfFile,updatePdfFile] = useState(null);
  const [numPages,setNumPages] = useState(null);
  const [active, setActive] = useState(false);
  const [loading, updateLoading] = useState(true);

  const { vanpool, rideDays, vehicleDetails, vanpoolStatus } = props;
  var makeModel = vehicleDetails.year +" "+vehicleDetails.make +" "+ vehicleDetails.model;
  var daysBefore = vehicleDetails.nextScheduledMaintenanceDate && dateUtility.checkDaysAgo(14,vehicleDetails.nextScheduledMaintenanceDate);
  async function downloadAndSaveVcc(download) {
    setActive(true);
    let response = await Http.getStatic(
      `${config.kongApiBaseUrl}/vanpools/vanpool/vcc`, { "Content-Type": "application/pdf" });
    if (response.ok) {
      const pdf = await response.blob();
      if (!download && pdf && pdf.type.indexOf('error') == -1) {
        const file = window.URL.createObjectURL(pdf);
        updatePdfFile(file)
        updateLoading(false)
      } else {
        saveAs(pdf, "agreement.pdf");
      }

    } else {
      updateLoading(false)
      props.showError();
    }
  };

  const onKeyDownVcc = (e) => {
    if (e.which === 13) {
      downloadAndSaveVcc();
    }
  };

  function onDocumentLoadSuccess({numPages: nextNumPages}) {
    setNumPages(nextNumPages);
}



function openCloseModal() {
  setActive(!active);
}

  return (
    <div  tabIndex='0' className="vanpool-information">
      {vanpoolStatus === 'P' || vanpoolStatus === 'A' ? null : <div className={`${ !daysBefore ? 'alert' : 'alert due' }`}>
        {
            daysBefore ? 
            <span>{'Maintenance Due'}</span> 
            :
            <span>{'Maintenance Complete'}</span>
        }
       
      </div>}
      <div className="vanpool-name">{vanpool.vanpoolName}</div>
      <div className="vanpool-type"> {makeModel}</div>
      {/* <div className="vanpool-desc">{vanpool.vanpoolName}</div> */}
      <div className="vanpool-image-container">
        <img src={vanpool.carClassImage} 
                  alt={vanpool.vehicleType}
                  className={`${vanpoolStatus === 'P' || vanpoolStatus === 'A' ? 'image-opacity' : 'image'
                }`}
                  onError={e => {
                  e.currentTarget.src = ImageNotAvailable;
                  e.currentTarget.className = "image-not-found";
                  }} />
        {/* <div className="vanpool-type"> {makeModel}</div> */}
      </div>
      <div className='details'>
      <div className="vanpool-license">
          <span className="key">License Plate</span>
          {
            vanpoolStatus === 'P' || vanpoolStatus === 'A' ? 
            <span className="value">{"Coming Soon!"}</span> :
            <span className="value">{vanpool.vehicleRegistrationNumber}</span>
          }
        </div>
        <div className="vanpool-license">
          <span className="key">Vanpool ID</span>
          <span className="value">{vanpool.vanpoolId}</span>
        </div>
      </div>
      <div className="commuteDays">
        <div className="heading">Days of Operation</div>
        <div className="oprational-days">
          {vanpool.operationalDays &&
            vanpool.operationalDays.map(day => (
              <button
                day={day}
                key={day}
                type="button"
                disabled={true}
                aria-label={day}
                className={`day-btn ${
                  rideDays.includes(day) ? 'active' : 'not-active'
                }`}
              >
                {day.substr(0, 3)}
              </button>
            ))}
        </div>
      </div>
      <RestrictFrom roles={[ PERMISSION_VANPOOL_STATUS_PRE_DELIVERY ]} required={false}>
        <div className='vcc'tabIndex={0}  onClick={() => {downloadAndSaveVcc(false);
            analyticsService.analyticsProcessEvent({
              "event": "vehicle_condition_checklist_download",
              "context": {
                "event_action": "vehicle condition checklist",
                "file-name":"agreement.pdf"
              }
            });
          }} onKeyDown={(e) => onKeyDownVcc(e)}>
          <img src={DownloadBlack} />
          Vehicle Condition Checklist
        </div>
      </RestrictFrom>
      { active && <div className="vcc-modal-container">
          <div className="modal">
            <div className="header">
              <span>Vehicle Condition Checklist</span>
              <span className="close" onClick={() => openCloseModal()} >
              <Close />
              </span>
            </div>
            <div className="body">
              {loading ? <div className='pdf-loader'>Loading pdf.. </div> :
                <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess} >
                  {Array.from(new Array(numPages),(el,index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} renderTextLayer={false} textlayer={false} renderAnnotationLayer={false}/>
                  ))}
                </Document>
              }
            </div>
            <div className="footer" onClick={() => downloadAndSaveVcc(true)} >
              <img src={Download}/>
              <button className='download-button'>DOWNLOAD</button>
            </div>
          </div>
      </div>
      }
    </div>
  );
}

export default connect(null, null)(VanpoolInformation);
