import * as actionTypes from '../../../redux/actionTypes';

import { checkIsPlanUpgrade, getFullPrice, getUpgradeText } from '../../../join-commute/helpers/vanHelpers';
import { useEffect, useState } from 'react';

import ArrowLeft from '../../../_styles/images/ArrowLeft.png';
import Callable from '../../../shared/Callable';
import ErrorCircleRounded from '../../../_styles/images/new-icons/error_circle_rounded.svg';
import { Link } from 'react-router-dom';
import Loading from '../../../shared/loading/Loading';
import Phone from '../../../_styles/images/PhoneGreen.png';
import PlanComponent from './PlanComponent';
import Steering from '../../../_styles/images/Steering.png';
import { connect } from 'react-redux';
import { numberWithCommas } from '../../../join-commute/helpers/commonHelper';
import seat from '../../../_styles/images/GreenSeat.png';
import analyticsService from '../../../shared/analytics/analytics-service';
import { getPackageNameForAnalytics } from '../../../shared/analytics/analytics-helpers';

function ChangeSubscription(props) {
  const [isRiderRate, showRiderdata] = useState(false);
  const [isDriverRate, showDriverData] = useState(false);
  const [isDriver, updateIsDriver] = useState(false);
  const [driverRates, setDriverRates] = useState([]);
  const [riderRates, setRiderRates] = useState([]);
  const [selectedOption, updateSelectedOption] = useState(null);
  const [loading, updateLoading] = useState(false);
  const [subscription, updateSubscripton] = useState({});
  const commute = props.profile.vanpool;
  const priceObj =
    subscription && subscription.totalAmount
      ? getFullPrice(subscription.totalAmount)
      : { price: '0', decimal: '0.0' };
  const plan =
    subscription &&
    subscription.package &&
    subscription.package.replace('X', '');

  useEffect(()=>{
    if(props.profile){
      if(props.profile.driver){
        updateIsDriver(props.profile.driver.isDriver);
      }
      if(props.profile.subscription){
        updateSubscripton(props.profile.subscription);
      }
    }
  }, [props.profile])

  const showData = data => {
    if (data == 'showRiderRates') {
      showRiderdata(true);
      showDriverData(false);
      updateSelectedOption(null);
    } else {
      showDriverData(true);
      showRiderdata(false);
      updateSelectedOption(null);
    }
  };

  function generateNewPlan(commuteDays, operationalDays) {
    let plan = [];

    for (const day in commuteDays) {
        if (commuteDays[day] || operationalDays[day].available) {
            plan.push(day);
        }
    }
    return plan;
}

function filterPackagesByLength(packages, length) {
  if (length < 2) {
      return [];
  } else if (length === 2) {
      return packages.slice(-1);
  } else if (length === 3) {
      return packages.slice(-2);
  }else if (length === 4) {
      return packages.slice(-3);
  } else {
      return packages;
  }
}

  useEffect(() => {
    
    if (props.profile && props.profile.subscriptionAPIInfo && isDriver !== undefined) {
      var vanpoolRates =
        props.profile.subscriptionAPIInfo.vanpool.vanpoolRates[0];
        vanpoolRates.rates.map(obj => {
          if (obj.discountType == 'Driver') {
            let filteredVanpoolDaysDriver = generateNewPlan(props.profile.subscriptionAPIInfo.participantCommuteDays, 
              props.profile.subscriptionAPIInfo.vanpool.operationalDays);
              let availableDaysDriver = filterPackagesByLength(obj.packages, filteredVanpoolDaysDriver.length);
            if(isDriver){
              let filteredAvailableDaysDriver = availableDaysDriver.filter((obj) => obj.packageName !== subscription.package)
              setDriverRates(filteredAvailableDaysDriver);
            } else {
              setDriverRates(availableDaysDriver);
            }
        } else {
          let filteredVanpoolDaysRider = generateNewPlan(props.profile.subscriptionAPIInfo.participantCommuteDays, 
              props.profile.subscriptionAPIInfo.vanpool.operationalDays);
          let availableDaysRider = filterPackagesByLength(obj.packages, filteredVanpoolDaysRider.length);
          if(!isDriver){
            let filteredAvailableDaysRider = availableDaysRider.filter((obj) => obj.packageName !== subscription.package)
            setRiderRates(filteredAvailableDaysRider);
          } else {
            setRiderRates(availableDaysRider);
          }
        }

      });
    }
  }, [props.profile, props.profile.subscriptionAPIInfo, isDriver, subscription]);

  function saveSelectedPlan() {
    const rates = isDriverRate ? driverRates : riderRates;
    var selectedPackage = rates[selectedOption];
    if(selectedPackage){
      const previousRole = isDriver? 'driver': 'rider';
      selectedPackage.role = isRiderRate ? 'rider' : 'driver';
      const isPlanUpgrade = checkIsPlanUpgrade(subscription.package, selectedPackage.packageName, previousRole, selectedPackage.role);
      selectedPackage.isPlanUpgrade = isPlanUpgrade;
      const upgradeText = getUpgradeText(subscription.package, selectedPackage.packageName, previousRole, selectedPackage.role);
      selectedPackage.upgradeText = upgradeText;
      selectedPackage.isRoleChange = previousRole !== selectedPackage.role;
      selectedPackage.analyticsPackageName = getPackageNameForAnalytics(selectedPackage.packageName);
    
      analyticsService.analyticsProcessEvent({
        "event": "change_subscription_plan_click_select_plan",
        "context": {
          "event_action": 'select plan',
          "event_detail": {
            "plan_selected": `${selectedPackage.analyticsPackageName}:${selectedPackage.role}`,
            "role_type": `${previousRole} to ${selectedPackage.role}`,
          }
        }
      });
      props.saveSelectedPlan(selectedPackage);
      props.history.push('/myprofile/change/plan/upgrade');
    }
  }

  useEffect(() => {
    if (!props.loaded) {
      updateLoading(true);
      props.init();
    }
    props.profile.driver && updateIsDriver(props.profile.driver.isDriver);
  }, []);

  useEffect(() => {
    if(isDriver){
      showDriverData(true);
      showRiderdata(false);
    } else {
      showDriverData(false);
      showRiderdata(true);
    }
  }, [isDriver])

  useEffect(() => {
    if(props.loaded) {
      updateLoading(false);
    }
  }, [props.loaded])

  function onTabKeyDown(type, e){
    if(e.which === 13){
      showData(type);
    }
  }

  return (
    <div className="change-subscription-container">
      <span aria-live='polite' role='alert'>
        <Loading isLoading={loading} />
      </span>
      <div className="change-subscription-page">
        <div className="header">
          <div className="backArrow">
            <Link className="overview-link" onClick={props.history.goBack}>
              <img alt="go back" src={ArrowLeft}></img>
            </Link>
          </div>
          <div className="heading">Change Subscription Plan</div>
        </div>
        <div className="content">
          <div className="top-section" role='group'>
            <div className="left-section">
              <div className="plan-details" role="group">
                <div className="plan active">Current Plan</div>
                {subscription && subscription.package === 'Unlimited' ? (
                  <div className="package">Unlimited</div>
                ) : (
                  <div className="package">{plan}-Day Select</div>
                )}
              </div>
              <div className="commute-days" role="group">
                <div className="commute-days-heading">{'Commute days'}</div>
                <div className="days">
                  {commute.commuteDays &&
                    commute.commuteDays.length &&
                    commute.commuteDays.map(day => (
                      <button
                        key={day}
                        aria-label={day}
                        disabled={true}
                        className="day-btn"
                      >
                        {day.substr(0, 3)}
                      </button>
                    ))}
                </div>
              </div>
            </div>
            <div className="right-section" role="group">
              <div className="rate-details">
                <img
                  src={isDriver ? Steering : seat}
                  alt={'seat image'}
                  aria-hidden="true"
                />
                <span>{isDriver ? 'Driver' : 'Rider'} rate</span>
              </div>
              <div className="rate">
                <div className="price-group" role='text'>
                  <span aria-hidden="true" className="price-group">
                    <span className="doller">{'$'}</span>
                    <span className="price">{numberWithCommas(priceObj.price)}</span>
                    <span className="point">.</span>
                    <span className="decimal">{priceObj.decimal}</span>
                  </span>
                  <span>
                    <span className="screenreader-only">{priceObj.price} dollars and {priceObj.decimal} cents</span>
                  </span>
                </div>
                <div className="perMonth">total per month</div>
              </div>
            </div>
          </div>
          <div className="bottom-section">
            <div className="heading">Change Subscription Plan </div>
            <div className="pre-text">
              Choose a plan that works best for you
            </div>
            <div className="tabs">
              <div
                className={`tab ${isDriverRate ? 'active' : ''}`}
                onClick={() => showData('showDriverRates')}
                onKeyDown={(e) => onTabKeyDown('showDriverRates', e)}
                role="button"
                aria-label={`Driver Rates ${isDriverRate ? 'selected' : 'not selected'}`}
                tabIndex={0}
              >
                Driver Rates
              </div>
              <div
                className={`tab ${isRiderRate ? 'active' : ''}`}
                onClick={() => showData('showRiderRates')}
                onKeyDown={(e) => onTabKeyDown('showRiderRates', e)}
                role="button"
                aria-label={`Rider Rates ${isRiderRate ? 'selected' : 'not selected'}`}
                tabIndex={0}
              >
                Rider Rates
              </div>
            </div>
            <div className='pref-text'>You can adjust your preferred driving days and frequency when you become an approved driver</div>
            {
              isDriverRate ?
              ((driverRates && driverRates.length !== 0) ? <div className="tab-container">
              {driverRates && driverRates.map((obj, index) => {
                if (obj.totalAmount) {
                  const totalAmount = parseFloat(obj.totalAmount);
                  var priceObject = getFullPrice(totalAmount);
                }
                return (
                  <PlanComponent isDriver={isDriver} rateObject={obj} priceObject={priceObject} isRiderRate={isRiderRate} index={index} selectedOption={selectedOption} updateSelectedOption={updateSelectedOption} />
                );
              })}
              <div className='driver-only-text'>*Standard rate assumes you will be an approved driver</div>
            </div> :
            <div className="no-plan">
              <img className="error-circle-rounded" src={ErrorCircleRounded} aria-hidden="true" />
              <span class="message">
                  No other plans available at this time for this vanpool.
                  Please check back soon or contact us on the following number.
              </span>
              <div className="callable-number" role='group'>
                        <img className="phone-icon" src={Phone} aria-hidden='true'></img>
                        <b><Callable className="greentext" phone="800 VAN 4 WORK" /></b>
              </div>
          </div>)
          :
          ((riderRates && riderRates.length !== 0) ? <div className="tab-container">
              {riderRates && riderRates.map((obj, index) => {
                if (obj.totalAmount) {
                  const totalAmount = parseFloat(obj.totalAmount);
                  var priceObject = getFullPrice(totalAmount);
                }
                return (
                  <PlanComponent isDriver={isDriver} rateObject={obj} priceObject={priceObject} isRiderRate={isRiderRate} index={index} selectedOption={selectedOption} updateSelectedOption={updateSelectedOption} />
                );
              })}
            </div> :
            <div className="no-plan">
              <img className="error-circle-rounded" src={ErrorCircleRounded} aria-hidden="true" />
              <span class="message">
                  No other plans available at this time for this vanpool.
                  Please check back soon or contact us on the following number.
              </span>
              <div className="callable-number">
                        <img className="phone-icon" src={Phone}></img>
                        <b><Callable className="greentext" phone="800 VAN 4 WORK" /></b>
              </div>
          </div>)
            }
          </div>
        </div>
        <div className="button-container">
          <button
            className="select-plan"
            disabled={selectedOption == null}
            onClick={saveSelectedPlan}
          >
            select plan
          </button>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
    loaded: state.profile.isLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: actionTypes.USER_PROFILE_LOADING });
    },
    saveSelectedPlan(selectedPackage) {
      dispatch({ type: actionTypes.SET_SELECTED_PLAN, data: selectedPackage });
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeSubscription);
