import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../redux/actionTypes';
import Loading from '../shared/loading/Loading';
import VanpoolInformation from './components/vanpoolInfo';
import EnvironmentDetails from './components/environmentDetails';
import OtherDetails from './components/otherDetails';
import analyticsService from '../shared/analytics/analytics-service';

function VapoolOverview(props) {
  const [loading, updateLoading] = useState(false);
  const [rideDays, updateRideDays] = useState([]);

  useEffect(() => {

    if(!props.rosterLoaded){
      props.loadRoster();
      updateLoading(true);
    }
    if(!props.vehicleDetails.loaded || !props.vanpoolInfo.loaded || !props.route.loaded){
      updateLoading(true);
      props.init();
    }
  }, []);

  useEffect(() => {
    if(props.vehicleDetails.loaded && props.vanpoolInfo.loaded && props.route.loaded && props.rosterLoaded) {
      updateLoading(false);
    }else{
      updateLoading(true);
    }
  }, [props.vanpoolInfo, props.vehicleDetails, props.route, props.rosterLoaded])

  useEffect(() => {
    if(props.participants && props.participantId) {
      var userDetails = props.participants.filter(p => props.participantId == p.participantId)
      if(userDetails.length){
        updateRideDays(userDetails[0].rideDays);
      }
     
    }
  }, [props.participants,props.participantId])

  return (
    <div className='vanpool-overview-page'>
      <Loading isLoading={loading} />
      <div className='container'>
        <div className='left-container'>
        <VanpoolInformation vanpool={props.vanpoolInfo} rideDays={rideDays} vehicleDetails={props.vehicleDetails} showError={props.showError} profile={props.profile} vanpoolStatus={props.vanpoolStatus} />
        <EnvironmentDetails /> 
        </div>
        <OtherDetails  vehicleDetails={props.vehicleDetails} history={props.history} participants={props.participants} route={props.route} profile={props.profile}/>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    vanpoolInfo: state.vanpoolInfo,
    participants: state.roster.rosterParticipants,
    participantId: state.userAuth.participantId,
    vehicleDetails : state.vehicle,
    route: state.vanpoolRoute,
    rosterLoaded: state.roster.loaded,
    profile: state.dashboard.profile,
    vanpoolStatus: state.userAuth.vanpoolStatus
  }
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: actionTypes.VANPOOL_INFO_LOADING });
      dispatch({ type: actionTypes.VEHICLE_LOADING });
      dispatch({ type: actionTypes.ROUTE_LOADING });
    },
    showError() {
      dispatch({ type: actionTypes.DISPLAY_ERROR, data: 'No Document found' });
    },
    loadRoster() {
      dispatch({type: actionTypes.ROSTER_LOADING});
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VapoolOverview);
