import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ProfileOverview from '../ProfileOverview';
import ApplyToDriveSummary from './update-license/apply-to-drive-summary/ApplyToDriveSummary';
import ApplyToDriveEdit from './update-license/ApplyToDriveEdit';
import UpdateLicense from './update-license/renew-update-license/UpdateLicense';
import CancelSummary from './cancel-summary/CancelSummary';
import ChangeSubscription from './change-subscription/ChangeSubscription';
import UpgradeSection from './change-subscription/upgrade-section/UpgradeSection';
import ChangeSubscriptionSummary from './change-subscription/ChangeSubscriptionSummary';
import EnterLicenseScreen from '../../join-commute/driver-license/EnterLicenseScreen';
import ChangeSubApplyToDrive from './update-license/apply-to-drive-summary/ChangeSubApplyToDrive';
import PersonalInformationEdit from './personal-information/PersonalInformationEdit';

export default function ProfileRoutes() {
  return (
    <div className="billing">
      <Switch>
        <Route exact path="/myprofile" component={ProfileOverview} />
        <Route path="/myprofile/manageSubscription"  render={() => <Redirect to="/myprofile" />} />
        <Route path="/myprofile/commuteDetails"  render={() => <Redirect to="/dashboard" />} />
        <Route path="/myprofile/edit" component={PersonalInformationEdit} />
        <Route path="/myprofile/applyToDrive" render={(props) => <ApplyToDriveEdit findAVan={false} {...props} navigateTo="/myprofile/summary" presentRoute="/myprofile/applyToDrive"/>} flow='profile'/>
        <Route path="/myprofile/summary" component={ApplyToDriveSummary} />
        <Route path="/myprofile/license/edit" component={UpdateLicense} />
        <Route path="/myprofile/cancel/summary" component={CancelSummary} />
        <Route path="/myprofile/change/plan" exact component={ChangeSubscription} />
        <Route path="/myprofile/change/plan/upgrade" exact component={UpgradeSection} />
        <Route path="/myprofile/change/plan/licenseNotification" exact render={(props) => <EnterLicenseScreen findAVan={false} {...props} />} />
        <Route path="/myprofile/change/plan/enterLicense" exact render={(props) => <ApplyToDriveEdit findAVan={false} {...props} flow='changeSubscription' navigateTo="/myprofile/change/plan/applyToDrive/summary"/>} />
        <Route path="/myprofile/change/plan/applyToDrive/summary" exact render={(props) => <ChangeSubApplyToDrive {...props}/>} />
        <Route path="/myprofile/change/plan/summary" component={ChangeSubscriptionSummary} />
      </Switch>
    </div>
  );
}
