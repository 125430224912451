import { Link } from 'react-router-dom';
import ArrowLeft from '../../_styles/images/ArrowLeft.png';
import InfoCircle from '../../_styles/images/new-icons/driver-status/info_circle.svg';
import SUV from '../../_styles/images/SUV.svg';
import { CLEAR_APPLY_TO_DRIVE_DATA } from '../../redux/actionTypes';
import { connect } from 'react-redux';
import analyticsService from '../../shared/analytics/analytics-service';

function EnterLicenseScreen(props) {

  function onContinue() {
    if(props.findAVan){
      props.clearApplicationData();
      props.history.push('/JoinCommute/license/add-license');
    } else {
      analyticsService.analyticsProcessEvent({
        "event": "click_generic",
        "context": {
          "event_action": 'continue'
         }
      })
      props.history.push('/myprofile/change/plan/enterLicense');
    }
  }

  return (
    <div className='license-page'>
      <div className='container' role='group'>
        <div className='header'>
          <div className='backArrow'>
            <Link className="overview-link" onClick={props.history.goBack}>
              <img alt='go back' src={ArrowLeft}></img>
            </Link>
          </div>
          <div className='heading'>Driver’s License</div>
        </div>
        <div className='main' role='group'>
          <div className='sections'>
            <div className='left-section'>
              <h2 className='second-heading'>
                Enter Your Driver’s License Information
              </h2>
              <div className='text'>
                Please complete your driver’s license information before proceeding.
              </div>
              <div className='blue-notes'>
                <div className='warning-icon'>
                  <img className='info-circle' alt='information-icon' src={InfoCircle} />
                </div>
                Please note that you are not an approved driver yet and will be able to drive only after this application is approved
              </div>
            </div>
            <div className='right-section'>
              <img className='van-image' alt='van-icon' src={SUV} />
            </div>
          </div>
          <div className='button-container'>
            <button className='continue-button' onClick={onContinue}>continue</button>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    clearApplicationData() {
      dispatch({ type: CLEAR_APPLY_TO_DRIVE_DATA});
    }
  }
}

export default connect(null, mapDispatchToProps)(EnterLicenseScreen);