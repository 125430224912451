import React from 'react';
import { Link } from 'react-router-dom';
import { RestrictFrom, RestrictTo } from '../permissions/RestrictTo';
import { PERMISSION_NOVANPOOL, PERMISSION_SUBSCRIPTION_UNCHANGED, PERMISSION_TRADITIONAL_USER, PERMISSION_VAN_STARTING_IN_FUTURE } from '../permissions/permissions';
import analyticsService from '../analytics/analytics-service';

function StationaryNav({ hideNavigation, className, isRecentLeadSubmitted }) {
  function sendAnalyticEvent() {
    analyticsService.analyticsProcessEvent({
      "event": "click_nav",
      "context": {
        "event_action": "navigation:find a van"
      }
    });
  }
  return (
    <div className={className + " stationary"}>
      <RestrictTo roles={[PERMISSION_TRADITIONAL_USER, PERMISSION_SUBSCRIPTION_UNCHANGED, PERMISSION_NOVANPOOL]} required={false}>
        <RestrictFrom roles={[PERMISSION_VAN_STARTING_IN_FUTURE]} required={false}>
        { hideNavigation ? null : 
          !isRecentLeadSubmitted &&
          <Link className="joinCommute" to="/JoinCommute" replace={'#/JoinCommute' === window.location.hash} onClick={sendAnalyticEvent}>
              Find A Van
          </Link>
        }
        </RestrictFrom>
      </RestrictTo>
    </div>
  );
}

export default StationaryNav;
