import React from 'react';
import { getMaxDaysFromPackage } from '../../../../join-commute/helpers/vanHelpers';
import ChangeCommuteDaysConfirmation from '../../change-days/ChangeCommuteDaysConfirmation';
import ChangeCommuteDaysPopup from '../../change-days/ChangeCommuteDaysPopup';
import analyticsService from '../../../../shared/analytics/analytics-service';

function CommuteDaysSection(props) {
    function clickHandler() {
        props.openChangeMyCommuteDays();
        analyticsService.analyticsProcessEvent({
            "event": "click_generic",
            "context": {
                "event_action": "change my commute days"
            }
        })
    }
    return (
        <div className="commute-days" role='group'>
            <div className="commute-days-heading">
                {'Commute days'}
            </div>
            <div className="days">
                {props.days.map(day => (
                    <div
                        key={day}
                        aria-label={day}
                        className="day-btn"
                        role='text'
                    >
                        {day.substr(0, 3)}
                    </div>))}
            </div>
            {props.startText && <div className='starting-date'>{props.startText}</div>}
            {props.endText && <div className='ending-date'>{props.endText}</div>}
            {props.lastRideDate && <div className='ending-date'>Last Ride Date: {props.lastRideDate}</div>}
            {props.endDate && <div className='ending-date'>Last Day of Subscription: {props.endDate}</div>}
            {!props.isSubscriptionChanged && !props.isNewSubscription && props.isSubscriptionActive &&
                <div className="change-my-commute">
                    <button className="change-commute green-link-underline" onClick={clickHandler}>
                        Change my commute days
                    </button>
                    {props.showChangeCommuteDaysPopup && 
                        <ChangeCommuteDaysPopup 
                            saveCommuteDays={props.saveCommuteDays} 
                            plan={props.subscription.package} 
                            maxDays={getMaxDaysFromPackage(props.subscription.package)} 
                            isDriver={props.isDriver} 
                            vanpoolInfo={props.vanpoolInfo} 
                            close={() => props.updateShowChangeCommuteDaysPopup(false)} 
                        />}
                    {props.showChangeCommuteDaysConfirmation && 
                        <ChangeCommuteDaysConfirmation 
                            selectedDays={props.selectedDays} 
                            startDate={props.pendingChangeStartDate} 
                            close={() => props.updateShowChangeCommuteDaysConfirmation(false)} 
                        />}
                </div>
            }
        </div>
    );
}

export default CommuteDaysSection;
