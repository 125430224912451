import { useEffect, useState } from 'react';
import Collapsible from 'react-collapsible';
import { isAndroid, isDesktop, isMobile } from 'react-device-detect';
import DriverDayRow from './DriverDayRow';
import { findObjectIndexByPropertyValue } from '../../join-commute/helpers/commonHelper';

export default function DriverMonthCollapsible(props) {
    const [isOpen, updateIsOpen] = useState(props.isOpen || false);
    const [monthData, updateMonthData] = useState(props.data || []);

    useEffect(() => {
        let filteredData = [];
        if (props.data) {
            filteredData = props.data.map(d => {
                if (d.scheduledDriverData && d.scheduledDriverData[0] && d.scheduledDriverData[0].driverId === props.currentParticipantID) {
                    d.isCurrentUser = true;
                }
                return d
            });
            if (props.indexSelected === 1) {
                filteredData = filteredData.filter((data) => {
                    if (props.commuteDays.includes(data.day)) {
                        return data;
                    }
                })
            }
            if (props.isToggled) {
                filteredData = filteredData.filter((data) => data.isCurrentUser || props.changedData.toCancel.includes(data.dateString) || props.changedData.toDrive.includes(data.dateString));
            }
        }
        updateMonthData(filteredData);
    }, [props.data, props.isToggled, props.currentParticipantID, props.indexSelected, props.commuteDays, props.changedData]);

    function checkAndExecuteAppStoreAction() {
        if (isDesktop) {
            props.updateOpenQRModal(true);
        } else if (isMobile) {
            if (isAndroid) {
                window.open('https://play.google.com/store/apps/details?id=com.commutewithenterprise.mobile');
            } else {
                window.open('https://apps.apple.com/us/app/commute-with-enterprise/id1440331154');
            }
        }
    }

    function onChangeData(type, data) {
        if (type === 'cancel') {
            let cancelObj = props.monthlyChangedData.toCancel;
            const monthsInCancelObj = Object.keys(cancelObj);
            const monthIndex = monthsInCancelObj.indexOf(data.month.toString());
            if (monthIndex !== -1) {
                const indexOfFound = findObjectIndexByPropertyValue(cancelObj[data.month], 'dateString', data.dateString);
                if (indexOfFound !== -1) {
                    let arr = cancelObj[data.month];
                    arr.splice(indexOfFound, 1);
                    if (arr.length === 0) {
                        delete cancelObj[data.month]
                    } else {
                        cancelObj[data.month] = [...arr];
                    }
                } else {
                    cancelObj[data.month] = [...cancelObj[data.month], data];
                }
            } else {
                cancelObj[data.month] = [data];
            }
            props.updateMonthlyChangedData({
                ...props.monthlyChangedData,
                toCancel: {
                    ...cancelObj
                }
            });
        } else if (type === 'drive') {
            let driveObj = props.monthlyChangedData.toDrive;
            const monthsInDriveObj = Object.keys(driveObj);
            const monthIndex = monthsInDriveObj.indexOf(data.month.toString());
            if (monthIndex !== -1) {
                const indexOfFound = findObjectIndexByPropertyValue(driveObj[data.month], 'dateString', data.dateString);
                if (indexOfFound !== -1) {
                    let arr = driveObj[data.month];
                    arr.splice(indexOfFound, 1);
                    if (arr.length === 0) {
                        delete driveObj[data.month]
                    } else {
                        driveObj[data.month] = [...arr];
                    }

                } else {
                    driveObj[data.month] = [...driveObj[data.month], data];
                }
            } else {
                driveObj[data.month] = [data];
            }
            props.updateMonthlyChangedData({
                ...props.monthlyChangedData,
                toDrive: {
                    ...driveObj
                }
            });
        }
        onDriveOrCancelClick(type, data.dateString);
    }

    function onDriveOrCancelClick(type, data) {
        if (type === 'cancel') {
            let arr = props.changedData.toCancel;
            const indexOfEle = arr.indexOf(data);
            if (indexOfEle !== -1) {
                arr.splice(indexOfEle, 1);
            } else {
                arr.push(data);
            }
            props.updateChangedData({
                ...props.changedData,
                toCancel: arr
            });
        } else if (type === 'drive') {
            let arr = props.changedData.toDrive;
            const indexOfEle = arr.indexOf(data);
            if (indexOfEle !== -1) {
                arr.splice(indexOfEle, 1);
            } else {
                arr.push(data);
            }

            props.updateChangedData({
                ...props.changedData,
                toDrive: arr
            });
        }
    }

    function Heading(classname) {
        var tempClass = 'arrow ';
        tempClass += isOpen ? 'top' : 'down';
        return (
            <div className={'collapsible-heading-container ' + classname} aria-expanded={isOpen} aria-controls={`month${props.index}`} tabIndex={0} role='button'>
                <div>
                    <div className='heading1 desktop'><span className='month-heading'>{props.month}</span></div>
                </div>
                <i className={tempClass}></i>
            </div>
        );
    }

    return (
        monthData.length > 0 ?
            <Collapsible open={isOpen} className='large-collapsible-container' handleTriggerClick={() => updateIsOpen(!isOpen)} trigger={Heading()} key={props.compKey} >
                {isOpen && <div className='collapsible-content' id={`month${props.index}`} key={props.compKey} >
                    <div className='row-container'>
                        {
                            monthData.map((dayData, index) => {
                                let driverInfo = dayData && dayData.scheduledDriverData && dayData.scheduledDriverData[0] && dayData.scheduledDriverData[0].participantInfo;
                                return <DriverDayRow
                                    driverInfo={driverInfo}
                                    dayData={dayData}
                                    index={index}
                                    month={props.month}
                                    indexSelected={props.indexSelected}
                                    checkAndExecuteAppStoreAction={checkAndExecuteAppStoreAction}
                                    handleClick={onChangeData}
                                    changedData={props.changedData}
                                    compKey={props.month + index}
                                />
                            })
                        }
                    </div >
                </div >}
            </Collapsible >
            :
            <Collapsible open={isOpen} className='large-collapsible-container' handleTriggerClick={() => updateIsOpen(!isOpen)} trigger={Heading('empty')} key={props.compKey}>
                {isOpen && <div className='collapsible-content' id={`month${props.index}`}>
                    <div className='row-container'>
                        <span className='no-data-text'>There are no assigned driving days for you this month</span>
                    </div>
                </div>}
            </Collapsible>
    );
}
