import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../redux/actionTypes';
import Alert from './Alert';
import BellIcon from '../_styles/images/new-icons/bell_icon.svg';
import redAlert from '../_styles/images/redAlert.png';
import Close from '../_styles/images/close-icon-green.svg';
import AlertsCollapsible from './AlertsCollapsible';
import analyticsService from '../shared/analytics/analytics-service';


function Alerts(props) {

    const [alerts, setAlerts] = useState([]);
    const [newAlerts, setNewAlerts] = useState([]);
    const [show, setShow] = useState(false);
    const [readAlerts, setReadAlerts] = useState([]);


    useEffect(() => {
        props.init();
    }, []);

    useEffect(() => {
        if (props.alerts.length) {
            var bellAlerts = props.alerts.filter((alert) => alert.priority == 3 || alert.priority == "" )
            setAlerts(bellAlerts);
            var newAlerts = bellAlerts.filter(alert => !alert.isRead);
            setNewAlerts(newAlerts)
        }
    }, [props.alerts]);

    const showAlert = (e) => {
        setShow(true);
        analyticsService.analyticsProcessEvent({
            "event": "click_generic",
            "context": {
                "event_action": "view alerts"
            }
        })
    }

    const hide = () => {
        setShow(false);
    };

    const onKeyDownAlert = (e) => {
        if (e.which === 13) {
            showAlert(e);
        }
    }

    const close = (e) => {
        if (readAlerts.length) {
            props.readAll(readAlerts);
            setReadAlerts([])
        }
        hide();
    }

    const markRead = (alert) => {
        let readAlert = alerts.filter(a => a.alertId == alert.alertId);
        alerts.map((a) => {
            if (a.alertId == alert.alertId) {
                a.isRead = true;
            }
        });
        var newAlerts = alerts.filter(alert => !alert.isRead);
        setNewAlerts(newAlerts)
        setReadAlerts(readAlerts => [...readAlerts, readAlert[0]]);
    }

    return (
        <div className="alerts-container" tabIndex={0}>
            <div className="bell" onClick={e => showAlert(e)} onKeyDown={onKeyDownAlert}>
                {newAlerts.length ? <img className='newAlertIcon' src={redAlert} /> : null}
                <img className='bell-icon' alt='alert icon' src={BellIcon} />
            </div>
            {show &&
                <div className="alerts toggle-tip">
                    <div className='header'>
                        <div className='heading'> Alerts </div>
                        <div className="close" onClick={e => close(e)}>
                            <img alt='close icon' src={Close} />
                        </div>
                    </div>
                    <div className="count"> {"You have"} <span>{newAlerts.length}</span> {"unread alert(s)"}</div>
                    <div className="alert-body">
                        {alerts && alerts.length > 0 && alerts.map((alert, index) => (
                            <AlertsCollapsible alert={alert} index={index} markRead={markRead} />

                        ))}
                        <div className={`note ${alerts.length ? "" : 'no-alert'}`}>
                            All alerts are removed after 30 days
                        </div>
                    </div>
                </div>}
        </div>

    );
}

function mapStateToProps(state) {
    return {
        alerts: state.alerts
    };
}
function mapDispatchToProps(dispatch) {
    return {
        init() {
            dispatch({ type: actionTypes.ALERTS_LOADING });
        },
        markAllRead() {
            dispatch({ type: actionTypes.MARK_ALL_ALERTS_READ });
        },
        readAll(alerts) {
            if (!alerts.length) return;
            dispatch({ type: actionTypes.READ_ALL_ALERTS, data: alerts });
        },
        deleteAlert(alert) {
            dispatch({ type: actionTypes.DELETE_ALERT, data: alert });
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
