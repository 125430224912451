import { useEffect, useRef, useState } from 'react';

export default function Dropdown(props) {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  function optionClicked(opt) {
    setIsOpen(false);
    props.onChange(opt);
  }

  var tempClass = 'time-arrow ';
  tempClass += isOpen ? 'up ' : 'down ';

  var dropdownClass = 'dropdown-list ';
  dropdownClass += isOpen ? 'unhide' : 'hide';

  const node = useRef();

  const clickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', clickOutside);
    return () => {
      document.removeEventListener('mousedown', clickOutside);
    }
  }, [isOpen])

  function onStateKeyDown(e){
    if(e.which === 13){
      toggle();
    }
  }

  function onStateSelectKeyDown(e, option) {
    if(e.which === 13){
      optionClicked(option);
    }
  }


  return (<div className='flexibility-container' ref={node}>
    <div className='flexibility-label'>
      <label id={props.name}>{props.label || ' '}</label>
    </div>

    <div className={props.error ? 'flexibility-header error' : 'flexibility-header'} tabIndex={0} role='button' aria-label={`${props.name} ${props.value}`} aria-labelledby={`${props.name} button`} onKeyPress={() => toggle()} onClick={() => toggle()} id={`${props.name}button`}>
    {props.value ?
        <div className='placeholder'>
          {props.value}
        </div> :
        <div className='placeholder-content'>
          {props.placeholder || ''}
        </div>
      }
      <i className={tempClass}></i>
    </div>
    {isOpen && <div className='dropdown-container' tabIndex={-1} role='listbox' aria-activedescendant={props.value}>
      <div className={dropdownClass}>
        {props.options.map(option =>
          <div role='button' className='list-item' addedclassname= {option === props.value ? 'selected' : ''} tabIndex={0} id={option} onClick={() => optionClicked(option)} onKeyDown={(e) => onStateSelectKeyDown(e, option)} key={option}>{option}</div>
        )}
      </div>
    </div>}
  </div>);
}