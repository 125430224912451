import React from 'react';
import { Block } from '../../shared/icons';
import dateUtility from '../../shared/dateUtility';
import DailyTripTimeModal from './DailyTripTimeModal';
import analyticsService from '../../shared/analytics/analytics-service';
import { numberWithCommas } from '../../join-commute/helpers/commonHelper';

const CalculateTime = ({
  homeBoundStartTime,
  homeBoundEndTime,
  workBoundStartTime,
  workBoundEndTime,
  date,
  workCommute,
  homeCommute,
  homeBoundMiles,
  workBoundMiles,
  vanpoolers
}) => {
  var toWork = 0;
  var toHome = 0;
  const allWork = vanpoolers.every(
    rider => rider.toWork 
  );
  const allHome = vanpoolers.every(
    rider => rider.toHome 
  );

  const isWork = vanpoolers.some( rider => rider.toWork === true)
  const isHome = vanpoolers.some( rider => rider.toHome === true)

  const userSelected = vanpoolers.some( rider => rider.singleSelected || rider.multiSelected)

if (userSelected) {
// ............work.................

 if (allWork) {
    toWork = dateUtility.getDuration(workBoundStartTime, workBoundEndTime);
    toWork = isNaN(toWork) ? 0 : toWork;
   } else if (isWork) {
    toWork = dateUtility.getDuration(workBoundStartTime, workBoundEndTime);
    toWork = isNaN(toWork) ? 0 : toWork;
   } else {
     toWork = 0;
   }
//........................ home .............
   if (allHome) {
    toHome = dateUtility.getDuration(homeBoundStartTime, homeBoundEndTime);
    toHome = isNaN(toHome) ? 0 : toHome;
   } else if (isHome){
    toHome = dateUtility.getDuration(homeBoundStartTime, homeBoundEndTime);
    toHome = isNaN(toHome) ? 0 : toHome;
   }else {
    toHome = 0;
   }


} else {
  if(workCommute) {
    toWork = dateUtility.getDuration(workBoundStartTime, workBoundEndTime);
    toWork = isNaN(toWork) ? 0 : toWork;
  } else {
    toWork = 0;
  }
  if (homeCommute) {
    toHome = dateUtility.getDuration(homeBoundStartTime, homeBoundEndTime);
    toHome = isNaN(toHome) ? 0 : toHome;
  } else {
    toHome = 0;
  }
}

var homeMiles = homeBoundMiles ? parseFloat(homeBoundMiles) : 0;
var workMiles = workBoundMiles ? parseFloat(workBoundMiles) : 0;
var totalMiles = homeMiles +  workMiles;
var Miles = numberWithCommas(totalMiles.toFixed(2)) + " " + "Miles"

  var totalTime = toHome + toWork;
  var result = dateUtility.getAddedDuration(toHome, toWork);
  if (totalTime && result) {
    return (
      <div className="tripTimeResult">
        <div className="time">{result}</div>
        <div className="mileage">{Miles}</div>
        <div className="tripEdit">Edit</div>
      </div>
    );
  } else {
    return <Block className="block" />;
  }
};

const TimeStatus = props => {
  const {
    closeDTTM,
    homeBoundStartTime,
    homeBoundEndTime,
    workBoundStartTime,
    workBoundEndTime,
    homeBoundMiles,
    workBoundMiles,
    editing,
    open,
    date,
    change,
    workCommute,
    homeCommute,
    isChanged,
    vanpoolers
  } = props;

  function openEditDailyTripDetails(e){
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "edit:daily trip details"
      }
    });
    open(e);
  }
  return (
    
    <div
      tabIndex="0"
      //  className={`trip-status trip-time ${isChanged ? 'changed' : ''}`}
      className={`trip-status trip-time`}
      onClick={e => {
        e.stopPropagation() || openEditDailyTripDetails(e);
      }}
    >
      <div className="tripTime">
        <CalculateTime
          homeBoundStartTime={homeBoundStartTime}
          homeBoundEndTime={homeBoundEndTime}
          workBoundStartTime={workBoundStartTime}
          workBoundEndTime={workBoundEndTime}
          workCommute={workCommute}
          homeCommute={homeCommute}
          homeBoundMiles={homeBoundMiles}
          workBoundMiles= {workBoundMiles}
          date={date}
          vanpoolers={vanpoolers}
        />
      </div>
      {editing ? (
        <DailyTripTimeModal
          active={editing}
          close={e => closeDTTM(e)}
          homeBoundStartTime={homeBoundStartTime}
          homeBoundEndTime={homeBoundEndTime}
          workBoundStartTime={workBoundStartTime}
          workBoundEndTime={workBoundEndTime}
          date={date}
          workCommute={workCommute}
          homeCommute={homeCommute}
          homeBoundMiles={homeBoundMiles}
          workBoundMiles= {workBoundMiles}
          change={(date, workStart, workEnd, homeStart, homeEnd, homeMiles, workMiles) =>
            change(date, workStart, workEnd, homeStart, homeEnd, homeMiles, workMiles)
          }
          vanpoolers={vanpoolers}
        />
      ) : null}
    </div>
  );
};

export default TimeStatus;
