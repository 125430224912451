import {useEffect, useState} from 'react';
import {getCardInfoFromBrand, numberWithCommas} from '../../join-commute/helpers/commonHelper';
import {getExpiryFromString, getFullPrice} from '../../join-commute/helpers/vanHelpers';
import {makeDecimal2numbers} from '../helper';

export default function PaymentCard(props) {
  
  const [priceAmount, updatePriceAmount] = useState({});
  const [cardImg, updateCardImg] = useState('/rideshare-static-assets/images/DefaultCard.png');
  const [cardType, updateCardType] = useState();
  const [expiry, updateExpiry] = useState();
  
  useEffect(() => {
    if(props.card) {
      const priceObj = getFullPrice(props.card.chargeAmount);
      updatePriceAmount(priceObj);
    }
  }, [props, props.card, props.card.chargeAmount])
  
  useEffect(() => {
    if(props.card && props.card.cardBrand) {
      const cardType = getCardInfoFromBrand(props.card.cardBrand);
      updateCardImg(cardType && cardType.imgSrc)
    }
  }, [props.card])

  useEffect(() => {
    if(props && props.card){
      if(props.card.isFederalBenefitCard){
        updateCardType('Federal Benefits Card');
      }
      else {
        if (props.card.isBackup) {
          updateCardType('Backup card');
        } else {
          updateCardType('Default card');
        }
      }

      if(props.card.expirationMMYY){
        const exp = getExpiryFromString(props.card.expirationMMYY, true);
        updateExpiry(exp);
      }
    }
    
  }, [props.card])
  
  return (
    props.card && <div className='display-card-container' role='group'>
      <div className='left-section'>
        <div className='card-image'>
          <img src={cardImg} aria-hidden='true'/>
        </div>
        <div className='content'>
          <div className='heading' role='text'>{props.card && props.card.cardBrand && props.card.cardBrand.toUpperCase()} ending in {props.card && props.card.partialAccountNumber}</div>
          <div className='type' aria-label={`${cardType}, expiring on ${expiry}`}>{cardType} | Exp: {expiry}</div>
          {props.card.isFederalBenefitCard && props.card.maxAuthorizedAmount && <div className='amount' aria-label={`Authorized amount ${makeDecimal2numbers(props.card.maxAuthorizedAmount)} dollars.`}>Authorized amount: ${numberWithCommas(makeDecimal2numbers(props.card.maxAuthorizedAmount))}</div>}
        </div>
      </div>
      {priceAmount && <div className='right-section' aria-label={`charges ${priceAmount.price} dollars and ${priceAmount.decimal} cents`}>
        <span aria-hidden>-</span>
        <div className='amount'>
          <div className='dollar'>$</div>
          <div className='price'>{priceAmount.price && numberWithCommas(priceAmount.price)}</div>
          <div className='price'>.{priceAmount.decimal}</div>
        </div>
      </div>}
    </div>
  );
}