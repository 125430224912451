import * as actionTypes from '../redux/actionTypes';

import { Email, Phone } from '../shared/icons';
import React, { Component } from 'react';

import Callable from '../shared/Callable';
import { PERMISSION_COORDINATOR } from '../shared/permissions/permissions';
import { RestrictFrom } from '../shared/permissions/RestrictTo';
import { connect } from 'react-redux';
import analyticsService from '../shared/analytics/analytics-service';

function clickContactEmail(email, type) {
  window.open(`mailto:${email}`, '_self');
  if (type === 'coordinator') {
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "coordinator email"
      }
    });
  } else if (type === 'branch')
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "customer support email"
      }
    });
}

class ContactInfo extends Component {
  componentDidMount() {
    if (!this.props.isLoaded) {
      this.props.init();
    }
  }

  render() {
    const { contactInfo } = this.props;

    if (!contactInfo) {
      return null;
    }

    return (
      <div className="contact-info">
        {contactInfo.coordinator ? (
          <RestrictFrom roles={[PERMISSION_COORDINATOR]} required={false}>
            <Contact
              {...contactInfo.coordinator}
              type="coordinator"
              title="My Coordinator"
            />
          </RestrictFrom>
        ) : null}
        {contactInfo.branchContactDetails ? (
          <Contact
            {...contactInfo.branchContactDetails}
            type="branch"
            title="Customer Support"
          />
        ) : null}
        {/* {contactInfo.participantEmployer ? (
          <Contact
            {...contactInfo.participantEmployer}
            type="employer"
            title={contactInfo.participantEmployer.employerName}
          />
        ) : null} */}
      </div>
    );
  }
}

function Contact({ type, fullName, title, email, phoneNumber }) {
  return (
    <div className={`contact ${type}`} role='group'>
      <h2 className="name">{title}</h2>
      <div className="title">{fullName}</div>
      <div className="phone">
        <Phone aria-hidden='true' />
        <Callable phone={phoneNumber} />
      </div>
      <div
        className="email"
        onClick={() => clickContactEmail(email, type)}
      >
        <Email aria-hidden='true' />
        {email}
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: actionTypes.CONTACT_INFO_LOADING });
    }
  };
}

function mapStateToProps(state) {
  return {
    contactInfo: state.dashboard.contactInfo,
    isLoaded: state.dashboard.contactInfoLoaded
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactInfo);
