/* eslint-disable no-undef */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import MapService from '../shared/map-service';

const controlsOff = {
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false
};

class RouteMap extends Component {
  constructor(props) {
    super(props);
    this.state = { ready: false };
  }

  onLoad() {
    if (!this.state.ready && this.map) {
      this.setState({ ready: true });
      if (this.props.padding) {
        setTimeout(
          () =>
            this.map &&
            this.map.fitBounds(this.map.getBounds(), this.props.padding),
          200
        );
      }
    }
  }

  shouldComponentUpdate(props) {
    // console.log('directions', props.directions)
    if (!this.state.ready) {
      return true;
    }

    if (!this.props.directions) {
      return true;
    }

    if (
      this.props.directions.geocoded_waypoints && this.props.directions.geocoded_waypoints.length !==
      props.directions.geocoded_waypoints.length
    ) {
      return true;
    }

    return this.props.directions.geocoded_waypoints.some((waypoint, i) => {
      const newWaypoint = props.directions.geocoded_waypoints[i];
      return newWaypoint.place_id !== waypoint.place_id;
    });
  }

  render() {
    const { directions, controls = true } = this.props;
    const { ready } = this.state;

    return (
      <div className={`google-map ${ready ? 'ready' : ''}`}>
        <MapService.MapWithDirections
          directions={directions}
          options={!controls ? controlsOff : null}
          mapRef={map => (this.map = map)}
          onTilesLoaded={() => this.onLoad()}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    directions: state.map.googleDirections
  };
}

export default connect(mapStateToProps)(RouteMap);
