import {useEffect,useState} from 'react';
import analyticsService from '../../../shared/analytics/analytics-service';
export default function CardInput(props) {
  const [placeholder,updatePlaceholder] = useState(true);

  function togglePlaceholder() {
    updatePlaceholder(!placeholder);
  }

  const [cardSrc,updateCardSrc] = useState('/rideshare-static-assets/images/DefaultCard.png');
  const [cardVal, updateCardVal] = useState('');

  useEffect(() => {
    if(props.cardType && props.cardType.imgSrc) {
      updateCardSrc(props.cardType.imgSrc)
    }
  },[props.cardType])

  useEffect(() => {
    if(props && props.disabledValue) {
      updateCardVal(props.disabledValue)
    }
  },[props])

  useEffect(() => {
    if(props.federalIssue){
      analyticsService.analyticsProcessEvent({
        "event": "user_error",
        "context": {
          "event_action": "Backup card cannot be a Federal Benefit card"
        }
      });
    }

    if(errorMessage){
      analyticsService.analyticsProcessEvent({
        "event": "user_error",
        "context": {
          "event_action": "please enter a valid card number"
        }
      });
    }
  },[props.federalIssue, errorMessage])
  
  const errorMessage = props.federalIssue? 'Backup card cannot be a Federal Benefit card' : !props.cardValid ? 'Please enter a valid credit card number' : props.sameCardNumber ? 'Backup card number cannot be the same as the primary card number' : '';
  return (
    <fieldset className='card-input-container'>
      <div className='label'>Card Number*</div>
      {
        props.disabled ?
        <input autoFocus={props.focus} aria-invalid={!!errorMessage} aria-required='true' id='cardNumber' style={{backgroundImage: `url(${cardSrc})`}} value={cardVal} className='disabled' readOnly></input>
        :
        <input autoFocus={props.focus} aria-invalid={!!errorMessage} aria-required='true' id='cardNumber' style={{backgroundImage: `url(${cardSrc})`}} className={!props.cardValid ? 'error' : ''} required={true} onBlur={togglePlaceholder} placeholder='Card Number' value={props.value} onChange={(e) => props.onChange(e.target.value)}></input>
      }
      {errorMessage && <div className='error-message' role="alert" aria-errormessage={errorMessage}>
        {errorMessage}
      </div>}
    </fieldset>
  );
}