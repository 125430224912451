import { COMMUTE_PROFILE_LOADING, LOADER_ACTIVE, USER_PROFILE_LOADING } from '../../redux/actionTypes';
import { Fragment, useEffect, useState } from 'react';
import { check2hoursApart, getTimeFromArrival, getUpdatedRoosterDays, getUserPackage, getVanAvailableDays, getVanOperationalDays } from '../helpers/commonHelper';

import AddressUtility from '../../shared/addressUtility';
import DaysInput from '../../shared/DaysInput';
import FlexibilityEntry from '../../shared/inputs/FlexibilityEntry';
import Loading from '../../shared/loading/Loading';
import { initialJoinCommuteState } from '../JoinCommuteReducer';
import analyticsService from '../../shared/analytics/analytics-service';
import LocationInput from '../../shared/inputs/LocationInput';
import MapService from '../../shared/map-service';
import TimeEntry from '../../shared/inputs/TimeEntry';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import SelectDays from '../../profile/components/change-days/select-days/SelectDays';

function TellUsAboutCommute(props) {
  const initState = {
    homeLatitude: 0,
    homeLongitude: 0,
    workLatitude: 0,
    workLongitude: 0,
    homeAddress: '',
    workAddress: '',
    stateName: '',
    arriveTime: '',
    departTime: '',
    flexArrivalMinutes: 0,
    flexDepartureMinutes: 0,
    rosterDays: { "Monday": false, "Tuesday": false, "Wednesday": false, "Thursday": false, "Friday": false, "Saturday": false, "Sunday": false },
    package: null,
    homeAddressExpanded: {},
  }

  const [commuteState, updateCommuteState] = useState(initState);

  const [buttonDisabled, updateButtonDisabled] = useState(true);

  const [isStartValid, updateIsStartValid] = useState(true);

  const [startInvalidMessage, updateStartInvalidMessage] = useState('');

  const [isEndValid, updateIsEndValid] = useState(true);

  const [endInvalidMessage, updateEndInvalidMessage] = useState('');

  const [isTimeValid, updateIsTimeValid] = useState(true);

  const [timeInvalidMessage, updateTimeInvalidMessage] = useState('');

  const [activeDays, updateActiveDays] = useState(0);

  const [arrivalTime, updateArrivalTime] = useState({});

  const [departTime, updateDepartTime] = useState({});

  const [lastChangedLocation, updateLastChangedLocation] = useState('');

  const [profile, updateProfile] = useState(initState);

  const [loading, updateLoading] = useState(false);

  const [userPackage, updateUserPackage] = useState(null);


  useEffect(() => {
    props.getPreviousCommuteData(props.history);
    analyticsService.analyticsProcessEvent({
      "event": "view_tell_about_commute",
      "context": {
        "event_action": "tell us about your commute"
      }
    });
    updateLoading(true)
  }, [])

  useEffect(() => {
    if (profile && !isEmpty(profile) && !(props.prePopulateData === false)) {
      (async () => {
        const startDetails = await MapService.getAddressAndLocation(profile.homeAddress);
        const stateName = MapService.getStateFromGeocode(startDetails.geocode);
        const homeAddressExpanded = AddressUtility.fromGeocode(startDetails);
        const endDetails = await MapService.getAddressAndLocation(profile.workAddress);
        if (profile.arriveTime && profile.arriveTime !== 'N/A') {
          updateArrivalTime(getTimeFromArrival(profile.arriveTime));
        }
        if (profile.departTime && profile.departTime !== 'N/A') {
          updateDepartTime(getTimeFromArrival(profile.departTime));
        }
        const activeDays = Object.values(profile.rosterDays).filter(val => val == true);
        updateActiveDays(activeDays);
        const prevCommuteState = {
          homeLatitude: startDetails.latitude,
          homeLongitude: startDetails.longitude,
          workLatitude: endDetails.latitude,
          workLongitude: endDetails.longitude,
          homeAddress: profile.homeAddress,
          homeAddressExpanded,
          workAddress: profile.workAddress,
          stateName,
          arriveTime: profile.arriveTime,
          departTime: profile.departTime,
          flexArrivalMinutes: profile.flexArrivalMinutes || (props.disableEditCommute ? 0 : null),
          flexDepartureMinutes: profile.flexDepartureMinutes || (props.disableEditCommute ? 0 : null),
          rosterDays: profile.rosterDays
        }
        updateCommuteState(prevCommuteState);
      })();
    }
  }, [profile]);

  useEffect(() => {
    let details = {};
    if (JSON.stringify(initialJoinCommuteState.userCommuteData) === JSON.stringify(props.joinCommuteDetails)) {

      details = {
        homeAddress: props.commuteProfile.homeAddress || props.joinCommuteDetails.homeAddress,
        workAddress: props.commuteProfile.workAddress || props.joinCommuteDetails.workAddress,
        stateName: props.joinCommuteDetails.stateName,
        arriveTime: props.commuteProfile.arriveAtWork || props.joinCommuteDetails.arriveTime,
        departTime: props.commuteProfile.departFromWork || props.joinCommuteDetails.departTime,
        flexArrivalMinutes: props.joinCommuteDetails.flexArrivalMinutes,
        flexDepartureMinutes: props.joinCommuteDetails.flexDepartureMinutes,
        rosterDays: getUpdatedRoosterDays(props.commuteProfile.activeDays, true),
      }
    } else {
      details = {
        homeAddress: props.joinCommuteDetails.homeAddress || props.commuteProfile.homeAddress,
        workAddress: props.joinCommuteDetails.workAddress || props.commuteProfile.workAddress,
        stateName: props.joinCommuteDetails.stateName,
        arriveTime: props.joinCommuteDetails.arriveTime || props.commuteProfile.arriveAtWork,
        departTime: props.joinCommuteDetails.departTime || props.commuteProfile.departFromWork,
        flexArrivalMinutes: props.joinCommuteDetails.flexArrivalMinutes,
        flexDepartureMinutes: props.joinCommuteDetails.flexDepartureMinutes,
        rosterDays: props.joinCommuteDetails.rosterDays,
      }
    }
    updateProfile(details);
  }, [props.commuteProfile])

  function validateData() {
    var isAnyLocationEmpty = commuteState.homeAddress === '' || commuteState.workAddress === '';
    var areLocationsEqual = commuteState.homeAddress === commuteState.workAddress;
    var isAnyTimeEmpty = commuteState.arriveTime === '' || commuteState.departTime === '' || JSON.stringify(arrivalTime) === JSON.stringify({}) || JSON.stringify(departTime) === JSON.stringify({});
    var areTimesEqual = commuteState.arriveTime === commuteState.departTime;
    var areTimes2hourApart = check2hoursApart(arrivalTime, departTime);
    var isAnyFlexibilityEmpty = commuteState.flexArrivalMinutes === null || commuteState.flexDepartureMinutes === null;

    if (!isAnyLocationEmpty && isStartValid && isEndValid && areLocationsEqual) {
      if (lastChangedLocation === 'start') {
        updateStartInvalidMessage('Address cannot be the same as arrival');
        analyticsService.analyticsProcessEvent({
          "event": "user_error",
          "context": {
            "event_action": "Address cannot be the same as arrival"
          }
        });
      } else if (lastChangedLocation === 'end') {
        updateEndInvalidMessage('Address cannot be the same as departure');
        analyticsService.analyticsProcessEvent({
          "event": "user_error",
          "context": {
            "event_action": "Address cannot be the same as departure"
          }
        });
      }
    } else {
      updateStartInvalidMessage('');
      updateEndInvalidMessage('');
    }

    if (!isAnyTimeEmpty) {
      if (areTimesEqual) {
        updateTimeInvalidMessage('Arrival and departure times cannot be the same');
        analyticsService.analyticsProcessEvent({
          "event": "user_error",
          "context": {
            "event_action": "Arrival and departure times cannot be the same"
          }
        });
        updateIsTimeValid(false);
      } else if (!areTimes2hourApart) {
        updateTimeInvalidMessage('Arrival and departure times must be more than two hours apart');
        analyticsService.analyticsProcessEvent({
          "event": "user_error",
          "context": {
            "event_action": "Arrival and departure times must be more than two hours apart"
          }
        });
        updateIsTimeValid(false);
      } else {
        updateTimeInvalidMessage('');
        updateIsTimeValid(true);
      }
    }

    return !isAnyLocationEmpty && !areLocationsEqual && isStartValid && isEndValid && !areTimesEqual && !isAnyTimeEmpty && !isAnyFlexibilityEmpty && (activeDays && activeDays.length >= 2) && areTimes2hourApart;
  }

  useEffect(() => {
    var isDataValid = validateData();
    updateButtonDisabled(!isDataValid);
  }, [commuteState, isStartValid, isEndValid, isTimeValid, activeDays, arrivalTime, departTime])

  useEffect(() => {
    if (buttonDisabled === true && props.lockNextAccordions) {
      props.lockNextAccordions(props.accordionInfo.id);
    }
  }, [buttonDisabled])

  function getLocationValue(key, value, valid) {
    var newValue = value;
    if (key === 'start') {
      newValue = {
        latitude: value.latitude,
        longitude: value.longitude,
        address: value.address,
      }
      updateLastChangedLocation(key);
      updateIsStartValid(valid);
    } else if (key === 'end') {
      newValue = {
        latitude: value.latitude,
        longitude: value.longitude,
        address: value.address,
      }
      updateLastChangedLocation(key);
      updateIsEndValid(valid)
    }
    return newValue;
  }

  function onFormChange(key, value, valid) {
    var tempState;
    if (key === 'start') {
      var data = value;
      data = getLocationValue(key, value, valid)
      const homeAddressExpanded = AddressUtility.fromGeocode(value);
      var updatedState = {
        ...commuteState,
        stateName: value.state,
        homeLatitude: data.latitude,
        homeLongitude: data.longitude,
        homeAddress: data.address,
        homeAddressExpanded,
      }
      updateCommuteState(updatedState);
    } else if (key === 'end') {
      var data = value;
      data = getLocationValue(key, value, valid)
      var updatedState = {
        ...commuteState,
        workLatitude: data.latitude,
        workLongitude: data.longitude,
        workAddress: data.address,
      }
      updateCommuteState(updatedState);
    }
    else {
      tempState = {
        ...commuteState,
        [key]: value
      };
      updateCommuteState(tempState)
      if (key === 'rosterDays') {
        var activeDays = Object.values(tempState.rosterDays).filter(val => val == true);
        updateActiveDays(activeDays);
      }
    }
  }

  useEffect(() => {
    const userPackage = getUserPackage(activeDays.length);
    updateUserPackage(userPackage);
  }, [activeDays])

  function updateDays(days) {
    const DAYS = getUpdatedRoosterDays(days.rideDays, true);
    onFormChange('rosterDays', DAYS, true);
  }

  function getActiveDays(days) {
    const keys = Object.keys(days).filter(el => days[el] == true);
    return keys;
  }

  function onTimeChange(key, value) {
    if (key === 'arriveTime') {
      updateArrivalTime(value);
    } else {
      updateDepartTime(value);
    }
    onFormChange(key, value.value);
  }

  function onContinue() {
    const state = {
      workLatitude: commuteState.workLatitude,
      workLongitude: commuteState.workLongitude,
      homeLatitude: commuteState.homeLatitude,
      homeLongitude: commuteState.homeLongitude,
      homeAddress: commuteState.homeAddress,
      workAddress: commuteState.workAddress,
      arriveTime: commuteState.arriveTime,
      departTime: commuteState.departTime,
      flexArrivalMinutes: commuteState.flexArrivalMinutes,
      flexDepartureMinutes: commuteState.flexDepartureMinutes,
      rosterDays: commuteState.rosterDays,
      stateName: commuteState.stateName,
      package: userPackage,
      homeAddressExpanded: commuteState.homeAddressExpanded,
      noOfDays: activeDays.length
    }
    props.continueClicked(state);
  }

  useEffect(() => {
    if (props.commuteProfileLoading === false && (profile.homeAddress !== '' && profile.workAddress !== '')) {
      updateLoading(false);
    } else {
      if (loading == false)
        updateLoading(true);
    }
  }, [props.commuteProfileLoading])

  return (
    <div className='collapsible-content-container'>
      <Loading isLoading={loading} />
      <fieldset className='commute-locations'>
      <legend className='visually-hidden'>Commute Locations</legend>
        <fieldset className='field commute-start'>
          <label htmlFor='commuteStart' className='attach' aria-label='commute start'>
            Commute Start
          </label>
          <LocationInput
            id='commuteStart'
            name='commuteStart'
            testid='start-input'
            placeholder='Enter a location'
            value={commuteState.homeAddress}
            onChange={(value, valid) => onFormChange('start', value, valid)}
            invalidmessage={startInvalidMessage}
            aria-invalid={!!startInvalidMessage}
            disabled={props.disableEditCommute}
          />
        </fieldset>
        <fieldset className='field commute-end'>
          <label htmlFor='commuteEnd' className='attach' aria-label='commute end'>
            Commute End
          </label>
          <LocationInput
            id='commuteEnd'
            name='commuteEnd'
            testid='end-input'
            placeholder='Enter a location'
            value={commuteState.workAddress}
            onChange={(value, valid) => onFormChange('end', value, valid)}
            invalidmessage={endInvalidMessage}
            aria-invalid={!!endInvalidMessage}
            disabled={props.disableEditCommute}
          />
        </fieldset>
      </fieldset>
      <fieldset className='commute-days'>
        <div className="commuteDays">
          {props.disableEditCommute ?
            <SelectDays
              value={getActiveDays(commuteState.rosterDays)}
              onChange={rideDays => updateDays({ rideDays })}
              availableDays={props.enabledDays}
              alreadySelected={[]}
              hideFullBadge={true}
            />
            :
            <DaysInput
              value={getActiveDays(commuteState.rosterDays)}
              onChange={rideDays => updateDays({ rideDays })}
            />}
        </div>
        <div className="minCommuteDays" aria-hidden={true}>
          <p>Please select a minimum of two days</p>
        </div>
      </fieldset>
      <fieldset className='time-entry'>
        <Fragment>
          <TimeEntry
            onChange={(value) => onTimeChange('arriveTime', value)}
            label='Arrive At Work'
            valid={isTimeValid}
            value={arrivalTime}
            aria-invalid={!isTimeValid}
            disabled={props.disableEditCommute}
          />
          <FlexibilityEntry
            onChange={(value) => onFormChange('flexArrivalMinutes', value, true)}
            label='Arrival Flexibility'
            message="Arrival Flexibility includes the selected amount of time before or after your designated arrival time"
            value={commuteState.flexArrivalMinutes}
            disabled={props.disableEditCommute}
            hideTooltip={props.disableEditCommute}
          />
        </Fragment>
        <Fragment>
          <TimeEntry
            onChange={(value) => onTimeChange('departTime', value)}
            label='Depart From Work'
            valid={isTimeValid}
            value={departTime}
            aria-invalid={!isTimeValid}
            disabled={props.disableEditCommute}
          />
          <FlexibilityEntry
            onChange={(value) => onFormChange('flexDepartureMinutes', value, true)}
            label='Departure Flexibility'
            message="Departure Flexibility includes the selected amount of time before or after your designated departure time"
            value={commuteState.flexDepartureMinutes}
            disabled={props.disableEditCommute}
            hideTooltip={props.disableEditCommute}
          />
        </Fragment>
      </fieldset>
      {timeInvalidMessage && <div className='invalid-address' role="alert">
        {timeInvalidMessage}
      </div>}
      <button id="firstContinue" tabIndex={0} aria-disabled={buttonDisabled} disabled={buttonDisabled} className='continue-button' onClick={onContinue}>{props.buttonText}</button>
    </div>

  );
}

function mapDispatchToProps(dispatch) {
  return {
    getPreviousCommuteData(history) {
      dispatch({ type: COMMUTE_PROFILE_LOADING, source: 'joinCommute', history });
      dispatch({ type: USER_PROFILE_LOADING, source: 'joinCommute', history });
    },
    updateLoading(data) {
      dispatch({ type: LOADER_ACTIVE, data });
    },
  };
}

function mapStateToProps(state) {
  return {
    commuteProfile: state.commuteProfile.commute,
    joinCommuteDetails: state.joinCommute.userCommuteData,
    joinCommuteState: state.joinCommute,
    joinCommuteloading: state.joinCommute.loading,
    commuteProfileLoading: state.commuteProfile.loading,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TellUsAboutCommute)
