import analyticsPageFactory from './analytics-page-factory';
import analyticsUserFactory from './analytics-user-factory';
const analyticsService = {};

function buildAnalytics(location) {
  if (location && !location.includes('/error')) {
    window._analytics = analyticsUserFactory.getUserObject();
  const userObject = analyticsUserFactory.getUserObject();
  window._analytics = {
    ...window._analytics,
    ...userObject
  }

    //replacing window._analytics.pageinfo to window._analytics.page as per recent docs
    if (window._analytics) {
      window._analytics.page = analyticsPageFactory.getPageObject(location);
    }
  }
}

function addPageloadToDataLayer(location, pagename, skipUserObject){
  var userObject =  skipUserObject ? {} : analyticsUserFactory.getUserObject();
  var pageObject;
  if(location){
    pageObject = analyticsPageFactory.getPageObject(location);
  }
  if(pagename){
    pageObject = pagename
  }
  
  window.dataLayer.push({
    "event": "page_load",
    "page": {
      "page_name": pageObject.pagename,
     },
    "process" : pageObject.process,
    ...userObject
  });
}

function attachToElement(eventType, data) {
  const element = document.getElementById('analytics');
  if(element){
    element.dispatchEvent(new CustomEvent(eventType, { detail: data }));
  }
}

analyticsService.pageChange = location => {
  buildAnalytics(location.pathname);
  addPageloadToDataLayer(location.pathname, null, false);
  attachToElement('analyticsReady', window._analytics);
};

analyticsService.modelOpened = (location, data) => {
  window._analytics = analyticsUserFactory.getUserObject();
  window._analytics.page = {
    pagename: location,
    commuterRole: data
  }
  attachToElement('analyticsReady', window._analytics);
};

// Newly added as part of MOVE for collecting analytics without page load/url change
analyticsService.getPageName = page => {
  addPageloadToDataLayer(null, page, true)
  if(window._analytics){
    window._analytics.page = page
    attachToElement('analyticsReady', window._analytics);
  }
};

// Newly added as part of MOVE
analyticsService.pageInfo = page => {
  //addPageloadToDataLayer(null,null,page)
  if(window._analytics){
    window._analytics.process = page
    attachToElement('analyticsReady', window._analytics);
  }
};

// Newly added as part of MOVE for Banners
analyticsService.BannerInfo = bannerTopic => {
   window._analytics.banner = {
    banner_topic: bannerTopic
   }
   attachToElement('analyticsReady', window._analytics);
 };

analyticsService.analyticsEvent = data => {
  buildAnalytics(window.location.hash);
  window._analytics.event = {
    message: data.error,
    code: data.errorCode,
    api: data.api,
    type: data.type
  };
  window._analytics = { ...window._analytics, ...data.data };
  attachToElement('analyticsEvent', window._analytics);
};

//Newly added as part of MOVE, need to push to dataLayer(gmt) instead of analytics
analyticsService.analyticsProcessEvent = data => {
  if(window.dataLayer){
    window.dataLayer.push({
      event: data.event,
      context: data.context,
      page: data.page,
      banner: data.banner,
      notification: data.notification
    });
  }
}

analyticsService.sendErrorPageLoadEvent = () => {
  const pageName = window && window._analytics && window._analytics.page && window._analytics.page.pagename;
  var userObject = analyticsUserFactory.getUserObject();
  if(pageName){  
    
    window.dataLayer.push({
      "event" : "page_load",
      "page": {
        "page_name": pageName
       },
       ...userObject
    });
  }
}

export default analyticsService;
