import React from 'react';
import NA_VALUE from './na';

const numpadLookup = {
  a: 2,
  b: 2,
  c: 2,
  d: 3,
  e: 3,
  f: 3,
  g: 4,
  h: 4,
  i: 4,
  j: 5,
  k: 5,
  l: 5,
  m: 6,
  n: 6,
  o: 6,
  p: 7,
  q: 7,
  r: 7,
  s: 7,
  t: 8,
  u: 8,
  v: 8,
  w: 9,
  x: 9,
  y: 9,
  z: 9
};

function parse(phone) {
  return (
    phone &&
    phone
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]/g, '')
      .replace(/[a-z]/g, char => numpadLookup[char])
  );
}

export default function Callable({ phone, onClick = () => {} }) {
  if (phone === NA_VALUE) {
    return <span className="callable">{NA_VALUE}</span>;
  }

  return (
    <span className="callable">
      <span className="desktop">{phone}</span>
      <a href={'tel:+1' + parse(phone)} onClick={onClick} >{phone}</a>
    </span>
  );
}
