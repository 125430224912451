import {  useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../redux/actionTypes';
import Loading from '../shared/loading/Loading';
import analyticsService from '../shared/analytics/analytics-service';


function Agreement(props) {
  const navigateTo = props.history.location && props.history.location.state && props.history.location.state.navigateTo ? props.history.location.state.navigateTo : '/dashboard';
  const [loading, setLoading] = useState(props.loading);

  useEffect(() => {
    if(window && window._analytics && window._analytics.process !== "sign contract"){
      analyticsService.getPageName({
        "event": "page_load",
        "page": {
          "page_name": "sign contract/vanpool payment"
        },
        "process": "sign contract"
      });
    }
  })


  useEffect((e) => {
    if (props.contractInfo.agreementURL) {
      setLoading(true);
      getDS(props.contractInfo.agreementURL);
    }
  }, [props.contractInfo.agreementURL]);


  const getDS = (agreementURL) => {
    docuSignClick.Clickwrap.render({
      agreementUrl: agreementURL,
      onAgreed: onAgreed,
      onDownloadCancelled: handleDownloadModal,
      onDownloaded: handleDownloadModal,
      onDeclining: handleDownloadModal,
      onError: handleDownloadModal,
      "settings": {
        format: "inline",
      }
    }, "#ds-terms-of-service");
    checkIframeLoaded();
    analyticsService.getPageName({
      "event": "page_load",
      "page": {
        "page_name": "sign contract/vanpool payment"
      },
      "process": "sign contract"
    });
  }

  const checkIframeLoaded = () => {
    const interval = setInterval(() => {
      const iframe = document.querySelector("#ds-terms-of-service iframe");
      if (iframe) {
        console.log("DocuSign Clickwrap iframe detected.");
        // Attach onload listener
        iframe.onload = () => {
          console.log("DocuSign Clickwrap iframe fully loaded.");
         setLoading(false);
         props.updateLoading(false);
          clearInterval(interval); // Stop checking once iframe is loaded
        };

        // If the iframe is already loaded
        if (iframe.complete || iframe.readyState === "complete") {
          console.log("DocuSign Clickwrap iframe already fully loaded.");
          setLoading(false);
          props.updateLoading(false);
          clearInterval(interval); // Stop the interval
        }
      }
    }, 2000); // Check every 2000 milliseconds
  };

  const handleDownloadModal = () => {
    setLoading(true);
    props.updateLoading(true);
    setTimeout(() => {
      props.history.push(navigateTo);
    }, 5000);
    analyticsService.analyticsProcessEvent({
      "event": "user_error",
      "context": {
        "event_action": "we ran into an error trying to load this data"
      }
    });
  };

  const onAgreed = (data) => {
    props.saveAgreementDetails({ agreementId: data.agreementId, clickwrapId: data.clickwrapId,isAgreed: true, agreementURL: null, fromBroucher: false });
   // props.updateContractStatus({agreementId: data.agreementId,clickwrapId:data.clickwrapId})
    setLoading(true);
    props.updateLoading(true);
    analyticsService.analyticsProcessEvent({
      "event": "agreement_agree_click",
      "context": {
        "event_action": "agree"
      }
  });
  analyticsService.getPageName({
      "page_name": "sign contract/vanpool payment",
      "process": "sign contract"
  });
    props.history.push(navigateTo);
  }

  return (
     <>
       <Loading isLoading={loading} /> 
      <div className="ds-terms-of-service" id="ds-terms-of-service">
      </div>
     </>
 
  );
}

function mapStateToProps(state) {
  return {
    contractInfo: state.joinCommute.contractInfo,
    loading: state.joinCommute.loading
  };
}
function mapDispatchToProps(dispatch) {
  return {
    saveAgreementDetails(data) {
      dispatch({ type: actionTypes.SAVE_CONTRACT_DETAILS, data });
    },
    updateLoading(data) {
      dispatch({type: actionTypes.LOADER_ACTIVE,data});
    },
    updateContractStatus(data) {
      dispatch({ type: actionTypes.UPDATE_CONTRACT_CLICKWRAP_STATUS, data: data });
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Agreement);
