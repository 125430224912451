import {useEffect,useState} from 'react';
import { getDaysForCalendar, isToday } from '../../../helpers/dateHelpers';

function CalendarDays(props) {
  const [currentDays,updateCurrentDays] = useState([]);
  const [selectedDay,updateSelectedDay] = useState(props.selectedDay);
  const [confirmDisabled,updateConfirmDisabled] = useState(true);
  
  useEffect(() => {
    if(props.reset) {
      updateSelectedDay(props.selectedDay)
    }
  }, [props.reset, props.selectedDay])
  
  useEffect(() => {
    if(selectedDay) {
      updateConfirmDisabled(false);
    } else {
      updateConfirmDisabled(true);
    }
  }, [selectedDay])

  useEffect(() => {
    const firstDay = new Date(new Date(props.year,props.month,1).setHours(0,0,0,0));
    const days = getDaysForCalendar(firstDay.getDay(),firstDay,selectedDay,props.enabledDays,props.startDay,props.endDay);
    updateCurrentDays(days)
  },[props.year,props.month,selectedDay,props.enabledDays,props.startDay, props.endDay])

  function dateConfirmed() {
    props.onConfirm(selectedDay);
  }

  function onDayKeyDown(e, day){
    if(e.which === 13){
      updateSelectedDay(day);
    }
  }

  return (
    <>
      <div className="table-content" role='application'>
        {
          currentDays.map((day) => {
            return (
              <div role='button' tabIndex={0} aria-label={`${day.dayOfWeek} ${day.monthName} ${day.number} ${day.strike ? " not available" : " available"} ${day.selected ? 'selected' : ''}`} key={day.date} className={"calendar-day" + (day.strike ? " striked" : "") + (day.selected ? " selected" : "")} onClick={day.strike ? ()=>{} : () => updateSelectedDay(day.date)} onKeyDown={day.strike ? ()=>{} : (e) => onDayKeyDown(e, day.date)}>
                {isToday(day.date) && <p className="today-label">TODAY</p>}
                <p className='date-number'>{day.number}</p>
              </div>
            )
          })
        }
      </div>
      <div className='confirm-button-container'>
        <button tabIndex={0} role='button' disabled={confirmDisabled} onClick={dateConfirmed}>{props.buttonText}</button>
      </div>
    </>
  )
}

export default CalendarDays;