import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';
import currencyFormatter from '../../shared/currencyFormatter';
import { Van, FuelPump, Tree, Money } from '../../shared/icons';
import { isAllowed } from '../../shared/permissions/RestrictTo';
import { PERMISSION_VANPOOL_STATUS_RENTAL } from '../../shared/permissions/permissions';

export class ImpactData extends Component {
  componentDidMount() {
    if(!this.props.impactDataLoaded){
      this.props.init(this.props.isNewSubscription);
    }
  }

  render() {
    if (!this.props[this.props.show]) {
      return null;
    }

    const impactData = this.props[this.props.show].current;
    const fuelSavings = currencyFormatter.formatUSD(impactData.fuelSavings);

    return (
      <div className="impact-data">
        <div className="miles-saved cell">
          <img src={Van} alt="van" aria-hidden='true'/>
          <span role='group'>
            <label>Miles Saved</label>
            <span className="value">
              {impactData.milesSaved.toLocaleString()}
            </span>
            <span className="units" aria-label='miles'>mi</span>
          </span>
        </div>
        <div className="fuel-saved cell" role='group'>
          <img src={FuelPump} alt="pump" aria-hidden='true'/>
          <span>
            <label>Fuel Gallons Saved</label>
            <span className="value">
              {impactData.fuelGallonsSaved.toLocaleString()}
            </span>
            <span className="units" aria-label='gallons'>gal</span>
          </span>
        </div>
        <div className="co2-saved cell" role='group'>
          <img src={Tree} alt="tree" aria-hidden='true'/>
          <span>
            <label>CO2 lbs Saved</label>
            <span className="value">
              {impactData.co2Savings.toLocaleString()}
            </span>
            <span className="units">lbs</span>
          </span>
        </div>
        <div className="fuel-savings cell" role='group'>
          <img src={Money} alt="money" aria-hidden='true'/>
          <span>
            <label>Fuel Cost Savings</label>
            <span className="value">
              {fuelSavings.substring(0, fuelSavings.indexOf('.'))}
            </span>
          </span>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    impactData: state.impactData,
    impactDataLoaded: state.impactData.impactDataLoaded,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    init(isNewSubscription) {
      if (isAllowed([PERMISSION_VANPOOL_STATUS_RENTAL])  && !isNewSubscription) {
        dispatch({ type: actionTypes.VANPOOLER_IMPACT_DATA_LOADING });
      } else {
        dispatch({ type: actionTypes.REGISTERED_USER_IMPACT_DATA_LOADING });
      }
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImpactData);
