import {getRandomColor} from '../../helpers/commonHelper'
import { useState } from 'react';

export function TextImage(props){
  const [color, updateColor] = useState(props.color || getRandomColor());
  return(
    <div className='image-container' title={props.tooltip || ''} style={{"background": color}} aria-hidden='true'>
      <div className='vanpooler-text'>
      {props.text}
      </div>
    </div>
  );
}