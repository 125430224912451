import React from 'react';
import DateUtility from '../../shared/dateUtility';
import { Pencil, User, StopWatch } from '../../shared/icons';

export default function StopsViewer({ stops, edit, editable, isSubscription }) {
  return (
    <div className="stops">
      { editable && !isSubscription ? (
        <div className="right">
          <Pencil onClick={edit} className="edit-icon" />
        </div>
      ) : null}
      <div className="route">{stops.map(RouteStop)}</div>
    </div>
  );
}

function RouteStop(point, index, stops) {
  const A = 'A'.charCodeAt();
  const isLastItem = stops.length - 1 === index;
  return (
    <div className="route-stop" key={index}>
      <div className="stop-time">
        <div className="clock" label={String.fromCharCode(index + A)}>
          {DateUtility.parseTimeAsTimeOnly(point.stopTime)}{' '}
          <span>{DateUtility.parseTimeAsAmPm(point.stopTime)}</span>
        </div>
      </div>
      <div className="column">
        <div className="stop-desc">{point.description}</div>
        <div className="stop-addr">{point.address}</div>
        <div className="footer-container">
          {isLastItem ? (
            <div className="wait-time-container" />
          ) : (
            <div className="wait-time-container">
              <StopWatch />
              <div className="wait-time">
                {noWait(isLastItem, point)
                  ? `No wait`
                  : `${point.waitTime}min wait`}
              </div>
            </div>
          )}
          <div className="participants">
            <div className="user-icon">
              <User />
            </div>
            <div>{point.participantCount ? point.participantCount : 0}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

function noWait(isLastItem, point) {
  return !!(isLastItem || point.waitTime === 0);
}
