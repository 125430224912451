import { connect } from 'react-redux';
import * as actionTypes from '../redux/actionTypes';
import { useEffect, useRef, useState } from 'react';
import { MONTH_NAMES } from '../shared/dateUtility';
import Loading from '../shared/loading/Loading';
import DriverMonthCollapsible from './driver-month-collapsible/DriverMonthCollapsible';
import EditSchedulePreferencesDropdown from './EditSchedulePreferencesDropdown';
import CommonQRModal from './QRModals/CommonQRModal';
import { getPreferredDays } from '../join-commute/helpers/commonHelper';
import { isEmpty } from 'lodash';
import DiscardChanges from '../profile/components/common/discardChangesPopup/DiscardChanges';
import CommonToast from '../shared/components/common-toast-message/CommonToast';
import { initDriverScheduleChangeData, initDriverScheduleMonthlyChangeData } from './DriverScheduleReducer';
import EditSchedulePreferencesPopup from './EditSchedulePreferencesPopup/EditSchedulePreferencesPopup';
import useError from '../shared/components/error-screen/useError';
import analyticsService from '../shared/analytics/analytics-service';

export const editSchedulePreferencesOptions = ['View Driver Schedule', 'Change My Assigned Days', 'Edit Schedule Preferences'];

function DriverScheduleMain(props) {
    const [loading, setLoading] = useState(true);
    const [isQRModalOpen, setQRModalOpen] = useState(false);
    const [indexSelected, setIndexSelected] = useState(0);
    const [isToggled, setIsToggled] = useState(false);
    const [changedData, updateChangedData] = useState(initDriverScheduleChangeData);
    const [disableButtons, updateDisableButtons] = useState(true);
    const [monthlyChangedData, updateMonthlyChangedData] = useState(initDriverScheduleMonthlyChangeData);
    const [toastOpen, updateToastOpen] = useState(false);
    const [openEditPreferencesPopup, updateOpenEditPreferencesPopup] = useState(false);
    const [driverPreferences, updateDriverPreferences] = useState();
    const [toastData, updateToastData] = useState({
        message: '',
        type: ''
    });
    const [days, updatedays] = useState([]);

    const navigationAlertNode = useRef();

    useEffect(() => {
        if (!props.driverSchedule.loaded || !props.profile.isLoaded) {
            setLoading(true);
            if (!props.driverSchedule.loaded) {
                props.init();
            }
            if (!props.profile.isLoaded) {
                props.loadProfile();
            }
        } else {
            setLoading(false);
        }
        if (props.driverSchedule) {
            props.driverSchedule.changedData ? updateChangedData(props.driverSchedule.changedData) : updateMonthlyChangedData(initDriverScheduleChangeData);
            props.driverSchedule.monthlyChangedData ? updateMonthlyChangedData(props.driverSchedule.monthlyChangedData) : updateChangedData(initDriverScheduleMonthlyChangeData);
            setIsToggled(props.driverSchedule.saveState.isToggled);
            setIndexSelected(props.driverSchedule.saveState.indexSelected);
        } else {
            updateMonthlyChangedData(initDriverScheduleMonthlyChangeData);
            updateChangedData(initDriverScheduleChangeData);
            setIsToggled(false);
            setIndexSelected(0);
        }
    }, []);
    useEffect(() => {
        if (props.driverSchedule.loaded && props.profile.isLoaded) {
            setLoading(false);
        } else {
            setLoading(true);
        }
        if (props.profile.isDriver && !props.driverPreferencesLoaded) {
            props.getDriverPreferences();
        }
        if (props.driverPreferencesLoaded) {
            updateDriverPreferences(props.driverPreferences);
        }
    }, [props.driverSchedule.loaded, props.profile.isLoaded, props.driverPreferencesLoaded, props.driverPreferences]);

    const handleToggle = () => {

        setIsToggled(prevState => {
            if (prevState !== true) {
                analyticsService.analyticsProcessEvent({
                    "event": "click_generic",
                    "context": {
                        "event_action": "only show my assigned days"
                    }
                });
            }
            return !prevState;
        });
    };

    useEffect(() => {
        if (isEmpty(changedData.toCancel) && isEmpty(changedData.toDrive)) {
            updateDisableButtons(true);
        } else {
            updateDisableButtons(false);
        }
    }, [changedData])

    function undoChanges() {
        updateChangedData({
            toCancel: [],
            toDrive: []
        });
        updateMonthlyChangedData({
            toCancel: [],
            toDrive: []
        });
        analyticsService.analyticsProcessEvent({
            "event": "click_generic",
            "context": {
                "event_action": "undo changes"
            }
        });
    }
    function goToReview() {
        props.saveChangedData(changedData, monthlyChangedData);
        props.savePresentState({
            isToggled,
            indexSelected
        });
        analyticsService.analyticsProcessEvent({
            "event": "click_generic",
            "context": {
                "event_action": "review changes"
            }
        });
        props.history.push('/driver-schedule/reviewChanges');
    }

    useEffect(() => {
        if (props.updateScheduleSuccess === false) {
            updateToastData({
                message: 'There was an error while trying to update your driver schedule. Please try again.',
                type: 'error'
            });
            updateToastOpen(true);
            props.resetDriverScheduleUpdate();
        } else if (props.updateScheduleSuccess === true) {
            updateToastData({
                message: 'Changes to your driving schedule have been successfully updated',
                type: 'success'
            });
            updateToastOpen(true);
            props.resetDriverScheduleUpdate();
        }
    }, [props.updateScheduleSuccess])

    useEffect(() => {
        updateChangedData(props.driverSchedule.changedData);
        updateMonthlyChangedData(props.driverSchedule.monthlyChangedData);
        setIsToggled(props.driverSchedule.saveState.isToggled);
        setIndexSelected(props.driverSchedule.saveState.indexSelected);
    }, [props.driverSchedule]);

    useEffect(() => {
        if (indexSelected === 2) {
            updateOpenEditPreferencesPopup(true);
        }
    }, [indexSelected])

    useEffect(() => {
        if (driverPreferences && driverPreferences.autoRepeatSchedule) {
            const preferredDays = getPreferredDays(driverPreferences.autoRepeatSchedule);
            updatedays(preferredDays);
        }
    }, [driverPreferences])

    function openCloseEditPreferencesPopup(isOpen) {
        updateOpenEditPreferencesPopup(isOpen);
        if (!isOpen) {
            setIndexSelected(0);
        }
    }

    useEffect(() => {
        if (props.driverPreferencesUpdateSuccess === false) {
            setLoading(false);
            updateToastData({
                message: 'There was an error while updating your driving preferences. Please try again',
                type: 'error'
            });
            updateToastOpen(true);
            props.resetDriverPreferencessUpdate();
        } else if (props.driverPreferencesUpdateSuccess === true) {
            setLoading(false);
            updateToastData({
                message: 'Your driving preferences were updated successfully',
                type: 'success'
            });
            updateToastOpen(true);
            props.resetDriverPreferencessUpdate();
        }
    }, [props.driverPreferencesUpdateSuccess])

    function savePreferences(data) {
        setLoading(true);
        props.updateDriverPreferences(data);
        openCloseEditPreferencesPopup(false);
    }

    useError(props.driverScheduleLoadError, props.history, props.driverScheduleLoadErrorString);

    return (
        <div className="driver-schedule-page">
            <Loading isLoading={loading} />
            <div className="driver-schedule-container">
                <div className='driver-schedule-list'>
                    <h1 className="heading">Driver Schedule</h1>
                    <div className='schedule'>
                        {props.profile.isDriver && (
                            <div>
                                <EditSchedulePreferencesDropdown indexSelected={indexSelected} updateIndex={setIndexSelected} />
                                {indexSelected === 1 && <h2 className='second-heading'>Select days you want to drive or cancel</h2>}
                                <div className='show-assigned-days'>
                                    <label htmlFor="assigned-days-toggle" className='text'>Only show my assigned days</label>
                                    <div className="toggle-container">
                                        <label className="toggle">
                                            <input
                                                type="checkbox"
                                                id="assigned-days-toggle"
                                                checked={isToggled}
                                                onChange={handleToggle}
                                                aria-label="Toggle to show only assigned days"
                                            />
                                            <span className="slider round" aria-hidden="true"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )}
                        {props.driverSchedule.driverScheduleList && Object.keys(props.driverSchedule.driverScheduleList).map((data, index) => (
                            <div key={data}>
                                <DriverMonthCollapsible
                                    isOpen={index === 0}
                                    data={props.driverSchedule.driverScheduleList[data]}
                                    month={MONTH_NAMES[data]}
                                    updateOpenQRModal={setQRModalOpen}
                                    isDriver={props.profile.isDriver}
                                    indexSelected={indexSelected}
                                    isToggled={isToggled}
                                    currentParticipantID={props.profile.participantId}
                                    commuteDays={props.profile && props.profile.vanpool && props.profile.vanpool.smallCommuteDays}
                                    compKey={index}
                                    updateChangedData={updateChangedData}
                                    changedData={changedData}
                                    monthlyChangedData={monthlyChangedData}
                                    updateMonthlyChangedData={updateMonthlyChangedData}
                                />
                            </div>
                        ))}
                    </div>
                    {indexSelected === 1 && <div className='bottom-button-section'>
                        <button className={'undo-button'} disabled={disableButtons} onClick={undoChanges}>undo changes</button>
                        <button className={'review-button'} disabled={disableButtons} onClick={goToReview}>Review changes</button>
                    </div>}
                </div>
                <DiscardChanges
                    heading='Unsaved Changes'
                    text='Are you sure you want to continue? Any unsaved changes will be lost.'
                    cancelButtonText='Cancel'
                    confirmButtonText='Yes, continue'
                    when={true}
                    navigate={path => {
                        props.resetDriverScheduleState()
                        props.history.push(path)
                    }}
                    shouldBlockNavigation={location => {
                        if (!isEmpty(changedData.toCancel) || !isEmpty(changedData.toDrive)) {
                            if (location.pathname !== '/driver-schedule' && location.pathname !== '/driver-schedule/reviewChanges') {
                                return true
                            }
                        }
                    }}
                    handleValidation={() => { return false }}
                    reference={navigationAlertNode}
                />
            </div>
            {isQRModalOpen && <CommonQRModal updateOpenQRModal={setQRModalOpen} />}
            <CommonToast message={toastData.message} type={toastData.type} isOpen={toastOpen} closeToast={() => updateToastOpen(false)} timeOut={6000} />
            {openEditPreferencesPopup &&
                <EditSchedulePreferencesPopup
                    saveSchedulePreferences={savePreferences}
                    vanDays={props.vanpoolInfo.commuteDays}
                    alreadyDrivingDays={days}
                    updateIsOpen={openCloseEditPreferencesPopup}
                    isFrequentDriver={driverPreferences.isFrequentDriver}
                />
            }
        </div>
    );
}

function mapStateToProps(state) {
    return {
        driverSchedule: state.driverSchedule,
        profile: state.profile,
        updateScheduleSuccess: state.driverSchedule.updateScheduleSuccess,
        vanpoolInfo: state.profile.vanpool,
        driverPreferencesUpdateSuccess: state.driverSchedule.updatePreferencesSuccess,
        driverPreferences: state.driverSchedule.preferences,
        driverPreferencesLoaded: state.driverSchedule.preferencesLoaded,
        driverScheduleLoadError: state.driverSchedule.scheduleLoadingError,
        driverScheduleLoadErrorString: state.driverSchedule.scheduleLoadingErrorString,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        init() {
            dispatch({ type: actionTypes.GET_DRIVER_SCHEDULE });
        },
        loadProfile() {
            dispatch({ type: actionTypes.USER_PROFILE_LOADING });
        },
        saveChangedData(changedData, monthlyChangedData) {
            dispatch({ type: actionTypes.UPDATE_CHANGED_DATA, changedData, monthlyChangedData })
        },
        savePresentState(saveState) {
            dispatch({ type: actionTypes.SAVE_DRIVER_SCHEDULE_PRESENT_STATE, saveState })
        },
        resetDriverScheduleUpdate() {
            dispatch({ type: actionTypes.UPDATE_DRIVER_SCHEDULE_RESET })
        },
        resetDriverScheduleState() {
            dispatch({ type: actionTypes.RESET_DRIVER_SCHEDULE_PRESENT_STATE })
        },
        updateDriverPreferences(data) {
            dispatch({ type: actionTypes.UPDATE_DRIVER_PREFERENCES, data });
        },
        resetDriverPreferencessUpdate() {
            dispatch({ type: actionTypes.UPDATE_DRIVER_PREFERENCES_RESET });
        },
        getDriverPreferences() {
            dispatch({ type: actionTypes.GET_DRIVER_PREFERENCES });
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DriverScheduleMain);
