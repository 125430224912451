import { useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import TickIcon from '../../_styles/images/tick-badge.svg';
import analyticsService from '../../shared/analytics/analytics-service';


function CompanyInformation(props) {
  const [selectedEmployerID, updateSelectedEmployerID] = useState('');
  const [employerName, updateEmployerName] = useState('');
  const [isDisabled, updateIsDisabled] = useState(true);
  const [companyName, updateCompanyName] = useState('');
  const [employersList, updateEmployersList] = useState([]);
  const [isLoading, updateLoading] = useState(false);

  useEffect(() => {
    if (selectedEmployerID === '' && companyName === '') {
      updateIsDisabled(true);
    } else {
      updateIsDisabled(false);
    }
  }, [selectedEmployerID, companyName]);

  useEffect(() => {
    if (!props.employers.length) {
      updateSelectedEmployerID("");
    } else {
      updateSelectedEmployerID(props.companyInfo.employerId);
    }
    if (props.companyInfo.employerId === '') {
      updateCompanyName(props.companyInfo.employerName);
    } else {
      updateEmployerName(props.companyInfo.employerName);
    }
  }, [props.companyInfo])

  useEffect(() => {
    updateEmployersList(props.employers);
    if (selectedEmployerID !== '' && !props.employers.find((employer) => employer.employerId === selectedEmployerID)) {
      updateSelectedEmployerID('');
    }
  }, [props.employers])

  function onContinue() {
    if (selectedEmployerID !== '') {
      props.continueClicked({
        employerId: selectedEmployerID,
        employerName: employerName,
        analyticsType: "from list"
      })
    } else if (companyName !== '') {
      props.continueClicked({
        employerId: '',
        employerName: companyName,
        analyticsType: "free text"
      })
    }
  }

  function optionClicked(employerId, employerName) {
    //user should be able to deselect the option
    if (employerId === selectedEmployerID) {
      updateSelectedEmployerID('');
      updateEmployerName('');
    } else {
      updateSelectedEmployerID(employerId);
      updateEmployerName(employerName);
      if (companyName !== '') {
        updateCompanyName('');
      }
    }
  }

  function onChangeCompanyName(value) {
    if (value.length <= 100 && value !== ' ') {
      updateCompanyName(value);
    }
    if (selectedEmployerID !== '') {
      updateSelectedEmployerID('');
    }
  }

  const comment = props.employers && props.employers.length > 0 ? 'Please select your company so we can properly match your route' : 'We were unable to find companies matching your commute';

  const commentOne = props.employers && props.employers.length > 0 ? 'Don’t see your company? Enter your company name here' : 'Enter your company name to continue';
  function clearOption() {
    updateSelectedEmployerID('');
  }

  useEffect(() => {
    updateLoading(props.isEmployerListLoading);
  }, [props.isEmployerListLoading])
  return (
    <div className='collapsible-content-container'>
      <div className='company-info'>
        {isLoading ?
          <div className="loader">
            <span className='hide' aria-hidden='false'>Loading page</span>
          </div>
          :
          <div aria-details='company list'>
            <div className='note'>{comment}</div>
            {employersList && employersList.length > 0 && <div className='employers-list'>
              {employersList.map((employer) => {
                let tempClass = 'employer-card';
                if (employer.employerId === selectedEmployerID) {
                  tempClass += ' selected';
                }
                return <button className={tempClass} key={employer.employerId} onClick={() => optionClicked(employer.employerId, employer.employerName)} role='button' tabIndex={0}>
                  {(employer.employerId === selectedEmployerID) && <div className='tick-icon-container'>
                    <img className='tick-icon' src={TickIcon} alt="selected" />
                  </div>}
                  <div className='text-container'>
                    <div>
                      {employer.employerName}
                    </div>
                  </div>
                </button>
              })}
            </div>}
          </div>}
        <div className='enter-company'>
          <div className='comment-one'>
            {commentOne}
          </div>
          <div className='comment-second'>
            We'll try searching for vanpools near your company's location
          </div>
          <fieldset className='company-input'>
            <label htmlFor='companyName'>Other</label>
            <input id='companyName' aria-label='Enter other company name' onClick={clearOption} value={companyName} onChange={(e) => onChangeCompanyName(e.target.value)} />
          </fieldset>
        </div>
        <button id="thirdContinue" disabled={isDisabled} aria-disabled={isDisabled} className='continue-button' onClick={onContinue}>Continue</button>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    employers: state.joinCommute.employersList,
    companyInfo: state.joinCommute.employerData,
    isEmployerListLoading: state.joinCommute.employerListLoading
  }
}

export default connect(mapStateToProps, null)(CompanyInformation)