import { isCardExpired } from "../../payment/helper";

export function isZipValid(value) {
    if(value.length === 5){
      return true
    } else {
      return false
    }
}

export function assignPriceToCards(price, cards) {
  const primaryCard = cards.find(card => card.isFederalBenefitCard && (card.isPrimary || card.isDefault));
  const backupCard = cards.find(card => !card.isFederalBenefitCard && card.isBackup);
  const notFederalPrimaryCard = cards.find(card => !card.isFederalBenefitCard && (card.isPrimary || card.isDefault));
  if(primaryCard && backupCard){
    const maxAuthAmount = parseFloat(primaryCard.maxAuthorizedAmount);
    if(price <= maxAuthAmount){
      primaryCard.chargeAmount = price;
      backupCard.chargeAmount = 0;
      return [primaryCard,backupCard];
    }
    else if(price > maxAuthAmount) {
      primaryCard.chargeAmount = maxAuthAmount;
      backupCard.chargeAmount = price - maxAuthAmount;
      return [primaryCard,backupCard]
    }
  }
  else if(notFederalPrimaryCard){
    notFederalPrimaryCard.chargeAmount = price;
    return [notFederalPrimaryCard]
  }
}
