import React, { Component } from 'react';
import { Check, Unchecked, Checked, Warning } from '../icons';
import CVCLeft from '../../_styles/images/cvc-left.svg';
import CVCAmex from '../../_styles/images/cvc-amex.svg';
import creditCardService from './creditCardService';
import FancyInput from '../FancyInput';

const MONTHS = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12'
];

export default class AddCard extends Component {
  constructor(props) {
    super(props);
    const year = new Date().getFullYear();
    this.YEARS = '0123456789'.split('').map((_, i) => '' + (i + year));

    this.state = {
      type: '',
      number: '',
      expMonth: MONTHS[0],
      expYear: this.YEARS[0],
      cvc: '',
      zip: '',
      saving: false
    };
  }

  updateCardNumber(value) {
    value = value.split(' ').join('');

    if (creditCardService.isNumber(value)) {
      const type = creditCardService.cardTypeForValue(value);
      const schedule = creditCardService.scheduleForCard(value);

      value = value.substr(0, Math.max.apply(Math, type.length));

      this.update({
        number: value,
        type: type.type,
        validNumber:
          type.type &&
          type.length.includes(value.length) &&
          creditCardService.luhn(value),
        cvc: '',
        validCVC: false,
        every: schedule.every,
        next: schedule.next
      });
    }
  }

  updateCVC(value) {
    let type = creditCardService.cardTypeForValue(this.state.number);
    if (creditCardService.isCvcValid(type.cvcLength, value)) {
      this.update({
        cvc: value,
        validCVC: value.length === type.cvcLength
      });
    }
  }

  updateZip(value) {
    if (/^\d{0,5}$/.test(value)) {
      this.update({
        zip: value,
        validZip: value.length === 5
      });
    }
  }

  updateCardExpiration(month, year) {
    let currDate = new Date();
    let currentMonth = currDate.getMonth() + 1;

    let validExp = !(
      parseInt(year, 10) === currDate.getFullYear() &&
      parseInt(month, 10) < currentMonth
    );

    this.update({ expMonth: month, expYear: year, validExp: validExp });
  }

  update(substate) {
    this.setState(substate);
    this.emit({ ...this.state, ...substate });
  }

  toggleSave() {
    this.update({ saving: !this.state.saving });
  }

  emit(state) {
    const {
      type,
      number,
      expMonth,
      expYear,
      cvc,
      zip,
      validNumber,
      validCVC,
      validZip,
      validExp,
      next,
      every,
      saving
    } = state;

    this.props.onChange({
      valid: !!(validNumber && validCVC && validZip && validExp),
      num: number,
      exp: expMonth + '/' + expYear.substr(2),
      brand: type.toUpperCase(),
      account: '*' + number.substr(-4),
      cvc,
      zip,
      next,
      every,
      saving
    });
  }

  render() {
    const {
      number,
      validNumber,
      validExp,
      numberFocused,
      expMonth,
      expYear,
      cvc,
      zip,
      type: brand,
      saving
    } = this.state;
    const showInvalid = number.length && !numberFocused && !validNumber;

    const formattedNumber = number
      .split('')
      .map((c, i) => (i % 4 === 3 ? c + ' ' : c))
      .join('')
      .trim();

    return (
      <div className="new-card-form">
        <div className="card-type">
          <FancyInput
            placeholder="Card Number"
            type="text"
            className={`card-number ${
              validNumber || numberFocused ? '' : 'invalid'
            }`}
            onChange={e => this.updateCardNumber(e.target.value)}
            onBlur={() => this.setState({ numberFocused: false })}
            onFocus={() => this.setState({ numberFocused: true })}
            value={formattedNumber}
            autoFocus
          />
          <span className={`card-valid ${validNumber ? 'valid' : ''}`}>
            {<Check focusable="false" />}
          </span>
          {showInvalid ? (
            <span className="card-invalid">
              Please enter a valid card number.
            </span>
          ) : null}
          {showInvalid ? (
            <Warning className="card-invalid-icon" focusable="false" />
          ) : null}
          <div className={`card-icons ${brand ? 'has-type' : ''}`}>
            {creditCardService
              .findValidCardType(type => type.type)
              .map(type => (
                <span
                  key={type.type}
                  className={`card-icon ${
                    type.type === brand ? 'entered' : ''
                  }`}
                >
                  <img src={type.imgSrc} alt={type.type} />
                </span>
              ))}
          </div>
        </div>
        <div className="select">
          <select
            className={`card-exp-month ${validExp ? '' : 'invalid'}`}
            defaultValue={expMonth}
            onChange={e => this.updateCardExpiration(e.target.value, expYear)}
          >
            {MONTHS.map(m => (
              <option key={m} value={m}>
                {m}
              </option>
            ))}
          </select>
        </div>
        <div className="select">
          <select
            className={`card-exp-year ${validExp ? '' : 'invalid'}`}
            defaultValue={expYear}
            onChange={e => this.updateCardExpiration(expMonth, e.target.value)}
          >
            {this.YEARS.map(y => (
              <option key={y} value={y}>
                {y}
              </option>
            ))}
          </select>
        </div>
        {validExp ? null : (
          <Warning className="card-exp-invalid-icon" focusable="false" />
        )}
        <div className="cvc">
          <FancyInput
            type="text"
            className="card-cvc"
            placeholder="CVC"
            value={cvc}
            onChange={e => this.updateCVC(e.target.value)}
          />
          <img src={brand === 'amex' ? CVCAmex : CVCLeft} alt="CVC help" />
        </div>
        <FancyInput
          type="text"
          className="card-zip"
          placeholder="Zip Code"
          value={zip}
          onChange={e => this.updateZip(e.target.value)}
        />
        {!this.props.recurring ? (
          <label className="save-card">
            <input
              type="checkbox"
              onKeyPress={e => e.key === 'Enter' && this.toggleSave()}
              onClick={() => this.toggleSave()}
              onChange={() => this.toggleSave()}
              checked={saving}
            />
            {saving ? (
              <Checked focusable="false" />
            ) : (
              <Unchecked focusable="false" />
            )}
            <span>Save this card for future use</span>
          </label>
        ) : null}
      </div>
    );
  }
}
