import React, { Component } from 'react';
import { User, Vanpool } from '../../shared/icons';

import ImpactData from './ImpactData';
import ImpactDataInfo from './ImpactDataInfo';
import { PERMISSION_VANPOOL_STATUS_RENTAL } from '../../shared/permissions/permissions';
import { isAllowed } from '../../shared/permissions/RestrictTo';
import analyticsService from '../../shared/analytics/analytics-service';

export default class ImpactDataContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: isAllowed([PERMISSION_VANPOOL_STATUS_RENTAL])
        ? 'participant'
        : 'registeredUser',
      analyticsSent: false,
    };
  }

  componentDidUpdate(newProps, newState) {
    if(!this.state.analyticsSent || ( this.state.analyticsSent && newState && newState.show !== this.state.show)){
      if(!this.state.analyticsSent){
        this.setState({
          ...this.state,
          analyticsSent: true
        })
      }
      if (this.state.show === 'participant') {
        analyticsService.analyticsProcessEvent({
          "event": "click_generic",
          "context": {
            "event_action": "view personal savings"
          }
        })
      } else if (this.state.show === 'vanpool') {
        analyticsService.analyticsProcessEvent({
          "event": "click_generic",
          "context": {
            "event_action": "view vanpool savings"
          }
        })
      }
    }
  }

  render() {
    const { show } = this.state;
    const participantActive = show === 'participant';
    const isRegisteredUser = show === 'registeredUser';

    return (
      <div className="impact-data-container solid" role='group'>
        <div className="impact-data-content">
          <div className="label">
            <h3>Estimated Savings</h3>
            <ImpactDataInfo  includeParking={this.props.includeParking} includeAccountDashboardInfo={this.props.includeAccountDashboardInfo}/>
            <div className="time">(Month-to-Date)</div>
          </div>
          <div className="impact-data-toggle">
            <div className="title">
              {participantActive ? 'Personal' : 'Vanpool'}
            </div>
            {isRegisteredUser ? (
              <div className="toggle">
                <div className="label">
                  <div className="title">
                    My Data <ImpactDataInfo  includeParking={this.props.includeParking} includeAccountDashboardInfo={this.props.includeAccountDashboardInfo}/>
                  </div>
                  <div className="time">Month to Date</div>
                </div>
              </div>
            ) : (
              <div className="toggle">
                <button
                  className={`${participantActive ? 'active' : ''} my-data`}
                  disabled={participantActive}
                  onClick={() => {
                    this.setState({ show: 'participant' });
                  }}
                >
                  <User />
                </button>
                <button
                  className={`${!participantActive ? 'active' : ''} vanpool`}
                  disabled={!participantActive}
                  onClick={() => {
                    this.setState({ show: 'vanpool' });
                  }}
                >
                  <Vanpool />
                </button>

              </div>
            )}
            <div className="mobile-only">
              {participantActive ? 'Personal' : 'Vanpool'}
            </div>
          </div>
        </div>
        <ImpactData show={show} isNewSubscription={this.props.isNewSubscription} />
      </div>
    );
  }
}
