import {getCardInfoFromBrand, initDate} from '../../join-commute/helpers/commonHelper';
import {getExpiryFromObject, getExpiryObjectFromString} from '../../join-commute/helpers/vanHelpers';
import {useEffect, useState} from 'react';

import CardCheckBox from './CardCheckBox';
import CardInformation from '../../join-commute/payment-screen/card-information/CardInformation';
import Close from '../../_styles/images/close-icon-green.svg';
import FederalComponent from '../../join-commute/payment-screen/federal-component/FederalComponent';
import creditCardService from '../../shared/credit-card/creditCardService';
import {removeDecimals} from '../../payment/helper';
import { isEmpty } from 'lodash';
import analyticsService from '../../shared/analytics/analytics-service';
import LegalCopy from './LegalCopy';

export default function EditCard(props) {
  const [cardNumber, updateCardNumber] = useState('');
  const [cardValid, updateCardValid] = useState(true);
  const [expirationDate, updateExpirationDate] = useState(initDate);
  const [cvv, updateCvv] = useState('');
  const [cvvValid, updateCvvValid] = useState(true);
  const [zip, updateZip] = useState('');
  const [zipValid, updateZipValid] = useState(true);
  const [dateValid, updateDateValid] = useState(true);
  const [cardType, updateCardType] = useState('');
  const [saveButtonDisabled, updateSaveButtonDisabled] = useState(true);
  const [checked, updateChecked] = useState(false);
  const [showSetBackup, updateShowSetBackup] = useState(true);
  const [showSetDefault, updateShowSetDefault] = useState(false);
  const [disableCheckbox, updateDisableCheckbox] = useState(false);
  const [showCheckbox, updateShowCheckbox] = useState(true);
  const [isBackup, updateIsBackup] = useState(false);
  const [isPrimary, updateIsPrimary] = useState(false);
  const [isFederal, updateIsFederal] = useState(false);
  const [brand, updateBrand] = useState('');
  const [methodReference, updateMethodReference] = useState('');
  const [authorizedAmount, updateAuthorizedAmount] = useState();
  const [isAmountValid, updateIsAmountValid] = useState(true);
  const [showFederalComponent, updateShowFederalComponent] = useState(null);

  useEffect(() => {
    if (props && props.card && !isEmpty(props.card)) {
      const card = props.card;
      updateCardNumber(card.partialAccountNumber);
      const exp = getExpiryObjectFromString(card.expirationMMYY);
      updateExpirationDate(exp);
      updateIsBackup(card.isBackup);
      updateBrand(card.cardBrand);
      updateIsPrimary(card.isPrimary);
      updateIsFederal(card.isFederalBenefitCard);
      const type = getCardInfoFromBrand(card.cardBrand);
      updateCardType(type);
      updateMethodReference(card.referenceId);
      if(card.isPrimary && card.isFederalBenefitCard){
        updateShowFederalComponent(true);
        analyticsService.getPageName({'pagename': 'payment/edit federal benefit card'});
        const amount = removeDecimals(card.maxAuthorizedAmount);
        updateAuthorizedAmount(amount);
      }
    }
    analyticsService.getPageName({ 'pagename': 'edit card'})
  }, [props.card])

  function onDateChange(type, value) {
    updateExpirationDate({
      ...expirationDate,
      [type]: value
    })
  }
  useEffect(() => {
    const today = new Date();
    if (expirationDate.month && expirationDate.year && expirationDate.month < today.getMonth() + 1 && expirationDate.year <= today.getFullYear()) {
      updateDateValid(false);
    } else {
      updateDateValid(true);
    }
  }, [expirationDate]);

  function checkAndUpdateCVV(value) {
    if (creditCardService.checkCVVForBrand(value, brand)) {
      updateCvv(value);
    }
  }

  function checkZip(value) {
    if (/^\d{0,5}$/.test(value)) {
      updateZip(value);
      if (value.length === 5) {
        updateZipValid(true);
      } else {
        updateZipValid(false);
      }
    }
  }

  function checkCardInfoValid(cvv) {
    const isCVVValid = cardType && cvv && cvv.length === cardType.cvcLength;
    if (cvv) {
      updateCvvValid(isCVVValid);
    }
    return isCVVValid;
  }

  useEffect(() => {
    const isValid = checkCardInfoValid(cvv);
    const federalValidation = isFederal ? (!!authorizedAmount && isAmountValid) : true;
    if (cvv && zip && expirationDate && isValid && zipValid && dateValid && federalValidation) {
      updateSaveButtonDisabled(false);
    } else {
      updateSaveButtonDisabled(true);
    }
  }, [cardNumber, cardType, cvv, zip, expirationDate, zipValid, dateValid, authorizedAmount, isAmountValid, isFederal]);

  function toggleCheckBox() {
    updateChecked(!checked);
  }

  function onContinue() {
    props.saveEditedCard({
      storedMethodReference: methodReference,
      cvc: parseInt(cvv),
      expirationMMYY: getExpiryFromObject(expirationDate, true),
      postalCode: zip,
      isBackup: showSetBackup && checked,
      isPrimary: showSetDefault && checked,
      maxAuthorizedAmount: authorizedAmount
    });
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "save card"
      }
    });
  }

  useEffect(() => {
    if (isBackup) {
      updateShowSetBackup(true);
      updateShowCheckbox(true);
      updateChecked(true);
      updateDisableCheckbox(true);
    } else if (isPrimary) {
      updateShowCheckbox(true);
      updateShowSetDefault(true);
      updateShowSetBackup(false);
      updateChecked(true);
      updateDisableCheckbox(true);
    } else if (!isPrimary && isFederal) {
      updateShowCheckbox(true);
      updateShowSetDefault(true);
      updateShowSetBackup(false);
    } else if (!isPrimary && props.cardDetails && props.cardDetails.federal !== 0 && props.cardDetails.primaryCards > 0) {
      updateShowSetBackup(true);
      updateShowCheckbox(true);
      updateChecked(false);
      updateDisableCheckbox(false);
    } else if (!isPrimary && props.cardDetails && props.cardDetails.federal === 0) {
      updateShowCheckbox(true);
      updateShowSetDefault(true);
    }
  }, [isBackup, isPrimary, isFederal, props.cardDetails]);

  useEffect(() => {
    if(checked && showSetDefault && isFederal && !showFederalComponent){
      updateShowFederalComponent(true);
    } else {
      updateShowFederalComponent(false);
    }

    if(checked){
      analyticsService.analyticsProcessEvent({
        "event": "click_generic",
        "context": {
          "event_action": "make default card"
        }
      });
    }
  }, [checked])

  function getHeading(){
    if(isPrimary) {
      return 'Edit default card';
    } else if(isBackup) {
      return 'Edit backup card';
    } else {
      return 'Edit card';
    }
  }

  return (
    <div className='card-operations-container' ref={props.reference} role='dialog' aria-describedby='heading' aria-modal='true'>
      <div className='header'>
        <div className='heading' id='heading'>{getHeading()}</div>
        <img className='close' src={Close} onClick={props.close} role='button' alt='close icon'/>
      </div>
      <div className='sub-heading'>Card Information</div>
      <CardInformation
        ref={props.reference}
        cardType={cardType}
        cardValid={cardValid}
        cardNumber={cardNumber}
        expirationDate={expirationDate}
        onDateChange={onDateChange}
        cvv={cvv}
        checkCVV={checkAndUpdateCVV}
        zip={zip}
        checkZip={checkZip}
        cvvValid={cvvValid}
        zipValid={zipValid}
        dateValid={dateValid}
        isCardInputDisabled={true}
        focus={true}
        />
      {showCheckbox && <CardCheckBox
        checked={checked}
        toggleCheckBox={toggleCheckBox}
        setDefault={showSetDefault}
        setBackup={showSetBackup}
        disabled={disableCheckbox}
      />}
      {isFederal && showFederalComponent && <FederalComponent
        authorizedAmount={authorizedAmount}
        onUpdateAuthorizedAmount={updateAuthorizedAmount}
        updateIsAmountValid={updateIsAmountValid}
        maxAmount={props.maxAmount} 
        isAmountValid={isAmountValid} />}
      <LegalCopy isFederal={isFederal} />
      <div className='buttons'>
        <button className='delete' onClick={props.deleteClicked}>Delete Card</button>
        <button disabled={saveButtonDisabled} className='save' onClick={onContinue}>Save</button>
      </div>
    </div>
  );
}
