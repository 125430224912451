import { useEffect, useState } from 'react';
import Close from '../../../../_styles/images/close-icon-green.svg';
import Highlight from '../../../../_styles/images/Highlight.png';
import analyticsService from '../../../../shared/analytics/analytics-service';


export default function ChangeSubscriptionConfirmation(props) {

  const [startDay, updateStartDay] = useState('');

  useEffect(() => {
    analyticsService.getPageName({
      'pagename': "profile/manage subscription/subscription updated confirmation modal"
    });
    analyticsService.analyticsProcessEvent({
      "event": "modal_view_generic",
      "context": {
        "event_action": "subscription updated modal"
      }
    })
  }, []);

  useEffect(() => {
    if (props && props.startDay) {
      updateStartDay(props.startDay);
    }
  }, [props])

  function keyDownHandler(e) {
    if (e.keyCode === 9) {
      e.preventDefault();
      const firstFocusElement = document.querySelector('.change-subscription-confirmation-popup .close');
      firstFocusElement.focus();
    }
  }

  return (
    <div
      className="charges-popup show"
      aria-live="polite"
      role="alert"
      id="modal"
    >
      <div className="card-operations-container change-subscription-confirmation-popup" id='change-subscription-confirmation-popup'>
        <div className="ccp-header">
          <img
            role="button"
            className="close"
            src={Close}
            onClick={props.close}
            alt="close icon"
          />
        </div>
        <div className="cancel-confirmation-popup-content">
          <div className="success-icon">
            <img className="successIcon" src={Highlight} aria-hidden='true' />
          </div>
          <div className="confirmationContainer">
            Your subscription plan has been updated and starts on <span className='date'>{startDay}</span>
          </div>
        </div>
        <div className="button-container">
          <button className="noThanks" onClick={props.close} onKeyDown={keyDownHandler}>
            Done
          </button>
        </div>
      </div>
    </div>
  )
}