import PriceComponent from '../common/price-component/PriceComponent';

export default function MoreSelect(props) {
  return (
    <div className='more' role='group'>
      <div className='tag'>
        <div className='text'>Ride 1 More Day</div>
      </div>
      {
        props.noOfDays <= 4
        ?
          <div className='select-heading'>{props.noOfDays}-Day Select</div>
        :
        <div className='select-heading'>Unlimited!</div>
      }
      <div className='comment2'>
        <ul>
          <li>Lower per-day cost</li>
        </ul>
      </div>
      {props.isDriver && !props.wasRider
        ?
        <div className='prices-container'>
          <PriceComponent small={false} charge={props.driverPrice} selected={props.selectedOption === 1 && props.selectedType === 0} onSelect={() => props.updateSelection(1,0)} />
        </div>
        :
        <div className='prices-container'>
          <PriceComponent
            small={true}
            charge={props.riderPrice} heading='Rider rate' selected={props.selectedOption === 1 && props.selectedType === 1} onSelect={() => props.updateSelection(1,1)} />
          <PriceComponent small={true} charge={props.driverPrice} heading='Willing to drive?' selected={props.selectedOption === 1 && props.selectedType === 0} onSelect={() => props.updateSelection(1,0)} />
        </div>
      }
      <div className='condition'>*All prices include Fuel, Maintenance, Roadside Assistance, Insurance, Taxes and Fees</div>
    </div>
  );
} 