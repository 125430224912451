import {useEffect, useState} from 'react';
import {initDate} from '../../join-commute/helpers/commonHelper';
import CardInformation from '../../join-commute/payment-screen/card-information/CardInformation';
import creditCardService from '../../shared/credit-card/creditCardService';
import CardCheckBox from './CardCheckBox';
import Close from '../../_styles/images/close-icon-green.svg';
import LegalCopy from './LegalCopy';

function BackupCard(props) {

  const [cardNumber, updateCardNumber] = useState('');
  const [cardValid, updateCardValid] = useState(true);
  const [expirationDate, updateExpirationDate] = useState(initDate);
  const [cvv, updateCvv] = useState('');
  const [cvvValid, updateCvvValid] = useState(true);
  const [zip, updateZip] = useState('');
  const [zipValid, updateZipValid] = useState(true);
  const [dateValid, updateDateValid] = useState(true);
  const [cardType, updateCardType] = useState('');
  const [buttonDisabled, updateButtonDisabled] = useState(true);
  const [isFederal, updateIsFederal] = useState(false);
  const [isCvvDisabled, updateCvvDisabled] = useState(true);
  const [checked, updateChecked] = useState(true);
  const [showSetBackup, updateShowSetBackup] = useState(true);
  const [disableCheckbox, updateDisableCheckbox] = useState(true);
  const [showCheckbox, updateShowCheckbox] = useState(true);


  useEffect(() => {
    return () => {
      updateCardNumber('');
      updateCardValid(true);
      updateExpirationDate(initDate);
      updateCvv('');
      updateCvvValid(true);
      updateZip('');
      updateZipValid(true);
      updateDateValid(true);
      updateCardType('');
      updateButtonDisabled(true);
      updateIsFederal(null);
      updateCvvDisabled(true);
    }
  }, [])

  function onDateChange(type, value) {
    updateExpirationDate({
      ...expirationDate,
      [type]: value
    })
  }

  useEffect(() => {
    const today = new Date();
    if (expirationDate.month && expirationDate.year && expirationDate.month < today.getMonth() + 1 && expirationDate.year <= today.getFullYear()) {
      updateDateValid(false)
    } else {
      updateDateValid(true);
    }
  }, [expirationDate])

  function checkAndUpdateCVV(value) {
    if (creditCardService.checkCVV(value, cardNumber)) {
      updateCvv(value);
    }
  }

  function checkZip(value) {
    if (/^\d{0,5}$/.test(value)) {
      updateZip(value);
      if (value.length === 5) {
        updateZipValid(true);
      } else {
        updateZipValid(false);
      }
    }
  }

  function checkCardNumber(value) {
    if (value.length >= 7) {
      const isFederalCard = creditCardService.checkIfFederal(value, props.binNumbers);
      updateIsFederal(isFederalCard);
    } else {
      updateIsFederal(false);
    }
    value = value.split(' ').join('');
    if (creditCardService.isNumber(value)) {
      const type = creditCardService.getCardTypeJoinCommute(value);
      updateCardType(type);
      value = value.substr(0, Math.max.apply(Math, type.length));
      updateCardNumber(value);
    }
  }

  function onContinue() {
    props.addCard({
      cardNumber,
      cardType,
      cvv,
      expirationDate,
      zip,
      isFederal: false,
      isBackup: true,
      isDefault: false,
    });
  }

  function checkCardInfoValid(cardNumber, cardType, cvv) {
    const isCVVValid = cardType && cvv && cvv.length === cardType.cvcLength;
    if (cvv) {
      updateCvvValid(isCVVValid);
    }
    const isCardNumberValid = cardType && cardNumber && cardType.length.includes(cardNumber.length);
    const isLunh = creditCardService.luhn(cardNumber);
    if (cardNumber && cardType) {
      updateCardValid(isCardNumberValid && cardType.type !== '' && isLunh);
    } else {
      updateCardValid(true);
    }
    return isCVVValid && isCardNumberValid && isLunh;
  }

  function toggleCheckBox() {
    updateChecked(!checked);
  }

  useEffect(() => {
    const isValid = checkCardInfoValid(cardNumber, cardType, cvv, zip);
    if (cardNumber && cardType && cvv && zip && expirationDate && isValid && !isFederal && zipValid && dateValid) {
      updateButtonDisabled(false);
    } else {
      updateButtonDisabled(true);
    }
  }, [cardNumber, cardType, cvv, zip, expirationDate, zipValid, dateValid]);

  useEffect(() => {
    if (!cardValid || cardNumber == '' || isFederal) {
      updateCvv('');
      updateCvvDisabled(true);
    } else {
      updateCvvDisabled(false);
    }
  }, [cardValid, cardNumber, isFederal])

  return (
    <div className='card-operations-container' ref={props.reference} role='dialog' aria-describedby='heading' aria-modal='true'>
      <div className='header'>
        <div className='heading' id='heading'>{props.heading}</div>
        {props.close && <img className='close' role='button' src={Close} onClick={props.close} alt='close icon'/>}
      </div>
      <div className='comment'>{props.text}</div>
      <div className='sub-heading'>Card Information</div>
      <CardInformation
        ref={props.reference}
        cardType={cardType}
        cardValid={cardValid}
        cardNumber={cardNumber}
        expirationDate={expirationDate}
        checkCardNumber={checkCardNumber}
        onDateChange={onDateChange}
        cvv={cvv}
        checkCVV={checkAndUpdateCVV}
        zip={zip}
        checkZip={checkZip}
        cvvValid={cvvValid}
        federalIssue={isFederal}
        isCvvDisabled={isCvvDisabled}
        zipValid={zipValid}
        dateValid={dateValid}
        focus={true}
        />
      {showCheckbox && <CardCheckBox
        checked={checked}
        toggleCheckBox={toggleCheckBox}
        setBackup={showSetBackup}
        disabled={disableCheckbox}
      />}
      <LegalCopy isFederal={isFederal}/>
      <button tabIndex={0} disabled={buttonDisabled} className='continue-button' onClick={onContinue}>continue</button>
    </div>
  );
}

export default BackupCard;