import NewUserBackground from '../../../_styles/images/new-icons/download-app/newUserBackground.png';
import VanpoolForming from '../../../_styles/images/new-icons/download-app/vanpoolForming.png';

export default function DashboardBackground(props) {
    const { name, image, hasMap, startDay, isInVanpool, isVanForming, isRecentLeadSubmitted } = props;
    return (
        <div className='top-dashboard-new' role='group'>
            <div className="dashboard-background">
                {!isInVanpool ? 
                <img src={NewUserBackground} aria-hidden='true'/>
                :<img src={VanpoolForming} aria-hidden='true'/>}
            </div>
            {!hasMap && <div className='green-strip'>
                <div className='left'>
                    <div className='header'>Welcome, {name}<span aria-hidden='true'>!</span></div>
                    {startDay && <div className='text'>Your Start Date is {startDay}</div>}
                    {isRecentLeadSubmitted && !isInVanpool && <div className='new-user-text'>We received your request to join a vanpool</div>}
                    {!isInVanpool && !isRecentLeadSubmitted  && <div className='new-user-text'>It Looks Like You're Not Part of a Vanpool</div>}
                    {isVanForming && <div className='new-user-text'>Your Vanpool is Now Forming!</div>}
                </div>
                {image &&
                    <div className='right'>
                        <img src={image} aria-hidden='true'></img>
                    </div>
                }
            </div>}
        </div>
    );
}