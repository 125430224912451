import { useEffect, useState } from 'react'
import TickIcon from '../../_styles/images/tick-badge.svg';
import CarSeat from '../../_styles/images/carSeat.svg';
import Steering from '../../_styles/images/steering.svg';
import { connect } from 'react-redux';
import analyticsService from '../../shared/analytics/analytics-service';

const schedules = [{
  text: 'I’ll drive as often as needed',
  comment: 'I’d like to drive nearly every day',
  recommended: true,
  image: Steering,
},
{
  text: 'I’ll drive occasionally',
  comment: 'I’d like to drive 1-2 times per week',
  recommended: false,
  image: Steering,
},
{
  text: 'I’d prefer just to ride',
  comment: 'I don’t want to drive at all',
  recommended: false,
  image: CarSeat,
}];

function DriveSchedule(props) {

  const [selectedOption, updateSelectedOption] = useState(1);

  function onContinue() {
    let tempObj = {
      isDesiredDriverRole: true,
      role: selectedOption,
      isFrequentDriver: false,
    }
    if (selectedOption === 2) {
      tempObj.isDesiredDriverRole = false;
      tempObj.isFrequentDriver = null;
    }
    if (selectedOption == 0){
      tempObj.isFrequentDriver = true;
    }
    props.continueClicked(tempObj);
  }

  useEffect(() => {
    updateSelectedOption(props.driveScheduleState.role)
  }, [props.driveScheduleState])

  return (
    <div className='collapsible-content-container'>
      <div className='drive-schedules'>
        {
          schedules.map((schedule, index) => {
            var tempClass = `schedule-option`;
            tempClass += index === selectedOption ? ' selected' : '';

            var textClass = 'text';
            textClass += index === 0 ? ' big' : '';

            return (<button className={tempClass} key={index} onClick={() => updateSelectedOption(index)} aria-pressed={index === selectedOption}>
              {(index === selectedOption) && <div className='tick-icon-container'>
                <img className='tick-icon' src={TickIcon} alt="selected" />
              </div>}
              <div className='schedule-container'>
                <div className='text-content'>
                  {schedule.recommended && <div className='recommended-text'>Recommended</div>}
                  <h2 className={textClass}>
                    {schedule.text}
                  </h2>
                  <div className='comment'>
                    {schedule.comment}
                  </div>
                </div>
                {(index === selectedOption) && <div className='schedule-image'>
                  <img className='image' src={schedule.image} alt="" />
                </div>}
              </div>
            </button>)
          })
        }
      </div>
      <div className='driver-comment'>
        Be the star of your commute. Volunteer to split driving duties with your crew.
      </div>
      <div className='comment-two'>
        You can always change your preferences later when you become an approved driver
      </div>
      <button id="secondContinue" disabled={false} className='continue-button' onClick={onContinue}>Continue</button>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    driveScheduleState: state.joinCommute.drivingScheduleData
  }
}

export default connect(mapStateToProps, null)(DriveSchedule)
