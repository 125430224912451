import Dropdown from '../../../join-commute/payment-screen/dropdown/Dropdown';

export default function PhoneSection(props) {
  return (
    <div className='number'>
      <div className='field'>
        <Dropdown
          label="Phone Type"
          onChange={(value) => props.onChange('type', value)}
          value={props.number.type}
          options={props.options} />
      </div>
      <div className='field number-field'>
        <div className='label'>
          <label>{props.numberLabel}</label>
        </div>
        <input type='text' className={props.valid ? 'number-field' : 'number-field error'} onChange={(e) => props.onChange('number', e.target.value)} value={props.number.number || ''} placeholder='Phone Number'></input>
        {props.valid ? null
          :
          <div className="invalid-text">
            {'Please enter a valid phone number'}
          </div>
        }
      </div>
      {
        props.number.type === 'Work' &&
        <div className='field extension'>
          <div className='label'>
            <label>Ext.</label>
          </div>
          <input onChange={(e) => props.onChange('extension', e.target.value)} value={props.number.extension || ''} placeholder='12345'></input>
        </div>
      }
    </div>
  );
}