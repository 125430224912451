import {useEffect,useState} from 'react';
import {connect} from 'react-redux';
import {GET_EMPLOYERS_LIST,SET_USER_COMMUTE_DATA,SET_USER_DRIVING_SCHEDULE,SET_USER_EMPLOYER_DATA,GET_MATCHED_VANPOOLS,GET_MATCHING_VANPOOLS,COMMUTE_PROFILE_LOADING,USER_PROFILE_LOADING,LOADER_ACTIVE, SET_USER_INFO_SUCCESS, RESET_USER_EMPLOYER_DATA} from '../redux/actionTypes';
import Loading from '../shared/loading/Loading';
import CompanyInformation from './company-information/CompanyInformation';
import DriveSchedule from './drive-schedule/DriveSchedule';
import JoinCommuteCollapsible from './join-commute-collapsible/JoinCommuteCollapsible';
import {initialJoinCommuteState} from './JoinCommuteReducer';
import TellUsAboutCommute from './tell-us-about-commute/TellUsAboutCommute';
import analyticsService from '../shared/analytics/analytics-service';

function JoinCommute(props) {

  const [accordions,updateAccordions] = useState([
    {
      id: 0,
      isOpen: true,
      heading: 'Tell us about your commute',
    },
    {
      id: 1,
      isOpen: false,
      heading: 'How often would you like to drive?'
    },
    {
      id: 2,
      isOpen: false,
      heading: 'What\'s the name of your company?'
    }
  ])
  
  const [lockArray, updateLockArray] = useState([false, true, true]);

  const [components,_] = useState([
    < TellUsAboutCommute history={props.history} lockNextAccordions={lockNextAccordions} accordionInfo={accordions[0]} continueClicked={updateTellUsAboutCommuteData} buttonText="CONTINUE"/>,
    < DriveSchedule accordionInfo={accordions[1]} continueClicked={updateDrivingScheduleData} />,
    < CompanyInformation accordionInfo={accordions[2]} continueClicked={updateEmployerData} />
  ]);
  
  
  useEffect(() => {
    props.history.replace("/JoinCommute");
    analyticsService.pageInfo("describe commute");
    props.resetSaveUserInfo(false);
  }, [])

  function unlockAndOpenAccordion(id) {
    var tempLockArray = lockArray;
    tempLockArray[id] = false;
    updateLockArray(tempLockArray);
    var temp = accordions.map((ac) => {
      if(ac.id == id) {
        ac.isOpen = true;
      } else {
        ac.isOpen = false;
      }
      return ac;
    })
    updateAccordions(temp);
  }

  function accordionClicked(id) {
    if(lockArray[id] == false) {
      unlockAndOpenAccordion(id);
      lockNextAccordions(id);
    }
    analyticsService.analyticsProcessEvent({
        "event": "describe_commute_expand_section",
        "context": {
          "event_action": "expand " +  `${id == 0 ? "tell us about your commute" : id == 1 ? "how often would you like to drive?" :"what's the name of your company?" }`
      }
    });
  }

  function updateTellUsAboutCommuteData(data) {
    unlockAndOpenAccordion(1);
    props.resetEmployerData();
    props.setUserCommuteData(data);
    props.getEmployersList(data);
    var activeDays = Object.keys(data.rosterDays).filter(el => data.rosterDays[el] == true);
    var daysString =  activeDays.map(d => d.substring(0, 2)).join(".").toLowerCase()
    analyticsService.analyticsProcessEvent({
      "event": "tell_about_commute_click_continue",
      "context": {
        "event_action": "continue",
        "event_detail": {
            "days_selected": daysString,
            "arrival_flexibility": data.flexArrivalMinutes,
            "departure_flexibility": data.flexDepartureMinutes,
            }
        }
    });
    analyticsService.analyticsProcessEvent({
      "event": "view_drive_frequency",
      "context": {
        "event_action": "how often would you like to drive?"
    }
    });
    analyticsService.getPageName({
      'pagename': "describe commute/drive frequency",
      'process': "describe commute"
    });
  }

  function updateDrivingScheduleData(data) {
    unlockAndOpenAccordion(2);
    props.setUserDrivingSchedule(data);
    analyticsService.analyticsProcessEvent({
      "event": "how_often_drive_click_continue",
      "context": {
        "event_action": "continue",
        "event_detail": `${data.role == 0 ? "i’ll drive as often as needed" : data.role == 1 ? "i’ll drive occasionally" : "i’d prefer just to ride" }`
        }
    });
    analyticsService.analyticsProcessEvent({
      "event": "view_company_name",
      "context": {
        "event_action": "what's the name of your company?"
    }
    });
    analyticsService.getPageName({
      'pagename': "describe commute/employer",
      'process': "describe commute"
    });
  }

  function updateEmployerData(data) {
    props.setUserEmployerData(data);
    props.getMatchingVanpools(props.history);
    props.updateLoading(true)
    analyticsService.analyticsProcessEvent({
      "event": "company_name_click_continue",
      "context": {
        "event_action": "continue",
        "event_detail": {
            "company": data.employerName,
            "company_type": data.analyticsType
            }
        }
  });
  }

  function lockNextAccordions(presentAccordionID) {
    var tempLockArray = lockArray;
    var temp = lockArray.map((ele, index) => {
      if(index > presentAccordionID) {
        tempLockArray[index] = true
      }
    });

    updateLockArray(tempLockArray);
  }
  
  useEffect(() =>{
    var tempLockArray = lockArray;
    if(JSON.stringify(props.joinCommuteState.drivingScheduleData) !== JSON.stringify(initialJoinCommuteState.drivingScheduleData)){
      tempLockArray[1] = false
    }
    if(JSON.stringify(props.joinCommuteState.employerData) !== JSON.stringify(initialJoinCommuteState.employerData)){
      tempLockArray[2] = false
    }
    updateLockArray(tempLockArray);
  }, [props.joinCommuteState])

  return (
    props.loading ? <Loading isLoading={true} /> :
    <div className='join-commute'>
      {/* <JoinCommuteHeader /> */}
      {
        accordions.map((accordion,index) => {
          return <JoinCommuteCollapsible component={components[index]} key={accordion.id} accordionInfo={accordion} accordionClicked={accordionClicked} isLocked={lockArray[index]}/>
        })
      }
      {/* <JoinCommuteFooter /> */}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    joinCommuteState: state.joinCommute,
    loading: state.joinCommute.loading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setUserCommuteData(data) {
      dispatch({type: SET_USER_COMMUTE_DATA,data});
    },
    resetEmployerData() {
      dispatch({type: RESET_USER_EMPLOYER_DATA});
    },
    setUserDrivingSchedule(data) {
      dispatch({type: SET_USER_DRIVING_SCHEDULE,data});
    },
    setUserEmployerData(data) {
      dispatch({type: SET_USER_EMPLOYER_DATA,data})
    },
    getEmployersList(data) {
      dispatch({type: GET_EMPLOYERS_LIST,data})
    },
    getMatchingVanpools(history) {
      dispatch({type: GET_MATCHING_VANPOOLS, history})
    },
    updateLoading(data) {
      dispatch({type: LOADER_ACTIVE,data});
    },
    resetSaveUserInfo(value) {
      dispatch({type: SET_USER_INFO_SUCCESS, data: value});
    }
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(JoinCommute)
