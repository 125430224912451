import VideoEmbed from '../video-embed/VideoEmbed';

function FirstScreen() {

  return (
    <div className='screen-container'>
      <div className='heading'>
        Your seat is saved
      </div>
      <div className='content-container'>
        <div className='content'>
          <div className='video'>
            <VideoEmbed url="https://www.youtube.com/embed/CxUX1220GZA"/>
          </div>
          <div className='sub-heading'>Welcome to your vanpool!</div>
          <div className='information'>
          You’re on the road to a better commute fueled by Enterprise, <span className='sub-text'>a leading mobility provider</span>. You’ll receive an email from us with more information on what to expect on your first day. In the meantime, click through to review route details and see who will be riding with you. 
          </div>
        </div>
      </div>
    </div>
  );
}

export default FirstScreen;